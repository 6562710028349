import {
  ALL_GROUPS,
  HANDLE_INPUT,
  MENU_ITEMS,
  ORDERS_COUNTER,
  ITEM_DETAIL,
  HANLE_ADDRESS_INPUT,
  STORES_LIST,
  HANDLE_GEOCODE_ADDRESS,
  GET_TAX_CASH,
  GET_TAX_CARD,
  BACKUP_STORES_LIST,
  COUPON_DATA,
  SAVE_DELIVERY_TIME,
  SAVE_TAX,
  SAVE_DISCOUNT,
  SAVE_CART_TOTAL,
  SAVE_DELIVERY_FEE,
  ORDER_PERIOD,
  STORES_OPTIONS,
  LOADING_INDICATOR,
  ORDER_RESET,
  HOUSE_NO,
  STREET_NAME,
  ADD_INFO,
  STREET_NO,
} from "../actions/menuType";

const initialState: any = {
  counter: {},
  itemData: {},
  delivery_address: "",
  geocoded_address: "",
  special_inst: "",
  kitchen_inst: "",
  allGroupsData: [] || "",
  groupsData: [] || "",
  menuItemsData: [] || "",
  taxDataCash: [] || "",
  taxDataCard: [] || "",
  storesList: [] || "",
  backupStoresList: [] || "",
  houseNo: "",
  Block: "",
  Area: "",
  City: "",
  Additional_address_info: "",
  couponSuccess: "",
  coupon: {},
  deliveryTime: "",
  orderPeriod: "now",
  taxAmount: 0,
  discountAmount: 0,
  deliveryfee: 0,
  CartTotal: 0,
  isLoading: false,
  storesoptions: [] || "",
  streetName:"",
  streetNo:"",
  addInfo:""
};

const menuReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HANDLE_INPUT:
      return {
        ...state,
        [action.input.name]: action.input.value,
      };
    case HANLE_ADDRESS_INPUT:
      return {
        ...state,
        delivery_address: action.address,
      };
    case HANDLE_GEOCODE_ADDRESS:
      return {
        ...state,
        geocoded_address: action.geo_address,
      };
    case ORDERS_COUNTER:
      return {
        ...state,
        counter: action.payload,
      };
    case ITEM_DETAIL:
      return {
        ...state,
        itemData: action.payload,
      };
    case ALL_GROUPS:
      return {
        ...state,
        allGroupsData: action.payload,
        groupsData: action.groupsData,
        isLoading: false
      };
    case MENU_ITEMS:
      return {
        ...state,
        menuItemsData: action.payload,
      };

      case ORDER_RESET:
      return {
        ...state,
        menuItemsData: action.payload,
        itemData: action.itemsData,
      };
    case STORES_LIST:
      return {
        ...state,
        storesList: action.payload,
      };
    case GET_TAX_CASH:
      return {
        ...state,
        taxDataCash: action.payload,
      };
    case GET_TAX_CARD:
      return {
        ...state,
        taxDataCard: action.payload,
      };
      case SAVE_DELIVERY_FEE:
        return {
          ...state,
          deliveryfee: action.deliveryfee
        }
    case BACKUP_STORES_LIST:
      return {
        ...state,
        backupStoresList: action.payload,
      };
    case COUPON_DATA:
      return {
        ...state,
        coupon: action.payload,
        couponSuccess: action.successResponse,
      };
    case SAVE_DELIVERY_TIME:
      return {
        ...state,
        deliveryTime: action.promiseTime,
      };
    case SAVE_TAX:
      return {
        ...state,
        taxAmount: action.taxAmount,
      };
    case SAVE_DISCOUNT:
      return {
        ...state,
        discountAmount: action.discountValue,
      };
    case SAVE_CART_TOTAL:
      return {
        ...state,
        CartTotal: action.CartTotal,
      };
      case ORDER_PERIOD:
        return {
          ...state,
          orderPeriod: action.orderPeriod
        }
        case STORES_OPTIONS: return {
          ...state,
          storesoptions: action.payload
      }
        case LOADING_INDICATOR: return {
          ...state,
          isLoading: true
        }
        case HOUSE_NO: return {
          ...state,
          houseNo: action.payload
        }
        case STREET_NAME: return {
          ...state,
          streetName: action.payload
        }
        case STREET_NO: return {
          ...state,
          streetNo: action.payload
        }
        case ADD_INFO: return {
          ...state,
          Additional_address_info: action.payload
        }
    default:
      return state;
  }
};
export default menuReducer;
