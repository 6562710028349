import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import BeautyStars from "beauty-stars";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { complaintReasonsList, getComplaints } from "../../redux/actions/customerComplaintAction";
import moment from "moment";
// import { logoutUser, feedbackList, deleteFeedback } from "../../redux";
// import { CustomerComplaintsProps } from "../../interfaces/customers";
// import { getComplaints } from "../../redux/actions/customerAction";


class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
    
  }
  handleDelete = (row: any) => {
    console.log("row",row)
    
    // this.props.delete(id);
  };
  render() {
    const { row } = this.props;
    const dateOrder = new Date(row.order_date).toLocaleDateString();
    // const dateOrder = row.order_date
    
    return (
      <div>
        <button
          title="View Feedback"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#DelFeedback${row.id}`}
          onClick={() => this.handleDelete(row)}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`DelFeedback${row.id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Customer Feedback
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
              <div className="form-row" >
                                            {/* <div className="form-group col-md-12"> <br /></div>
                                            <div className="form-group col-md-12"><h4 id="txt">Feedback Form</h4></div> */}
                                            <div className="form-group col-md-6">
                                                <h5 id="txt">  Name</h5>
                                                <input name="fullname" disabled defaultValue={row.name} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <h5 id="txt">Phone</h5>
                                                <input name="phone" disabled defaultValue={row.phone_number} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                            </div>
                                            
                                            <div className="form-group col-md-6">
                                                <h5 id="txt">Email</h5>
                                                <input name="email" disabled defaultValue={row.email_address} style={{ fontSize: "15px" }} type="email" className="form-control bg-white" id="round" required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <h5 id="txt">Store</h5>
                                                <input type="text" name="dateOfOrder" disabled defaultValue={row.store_name} required className="form-control bg-white"  id="round"/>
                                            </div>
                                            
                                            <div className="form-group col-md-6">
                                                <h5 id="txt">Order Date </h5>
                                                <input type="text" name="dateOfOrder" disabled defaultValue={dateOrder} required data-msg="Please Enter Order Date" className="form-control bg-white"  id="round"/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <h5 id="txt">  Receipt Number</h5>
                                                <input name="receiptnumber" disabled defaultValue={row.receipt_number} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <h5 id="txt">  Feedback Type</h5>
                                                <input name="receiptnumber" disabled defaultValue={row.type} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" />                                          
                                                </div>
                                                {row.reason && 
                                                <div className="form-group col-md-12">
                                                <h5 id="txt">Reason</h5>
                                                <input name="receiptnumber" disabled defaultValue={row.reason} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" />                                          
                                                </div>
                                                }
                                            <div className="form-group col-md-12">
                                                <h5 id="txt">Feedback</h5>
                                                <textarea name="feedbackMessage" id="round2" disabled style={{width:'100%', height:'100px'}}>
                                                    {row.message}
                                                </textarea>
                                            </div>
                                            
                                        </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ResponseMessageFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        console.log(row)
        return (
            <div>
                {row.store_response &&
                    <button title="View Items" data-toggle="modal" data-target={`#itemdetails${row.id}`} className="btn btn-outline-info"><i className="fa fa-comment"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`itemdetails${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Complaint response from Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                        <div className="form-group col-md-12">
                                        <h5 id="txt">Store Response</h5>
                                        <textarea disabled name="feedbackMessage" defaultValue={row.store_response} id="round2" style={{ width: '100%', height: '100px' }}>
                                        </textarea>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function responseFormatter(cell: any, row: any, props: any) {
    return (
        <ResponseMessageFormatter row={row} data={props} />
    );
}

function statusFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 0 ? "pending" : "resloved"}</span>
        </div>
    )
}
function dateFormater(cell:any,row:any){
  return (
    <div>
        <span {...((cell !== "" && cell !== null) && { title: moment(cell).local().format('YYYY-MM-DD HH:mm') })}> {(cell !== "" && cell !== null && !cell.includes('0000-00-00')) && moment(cell).local().format('YYYY-MM-DD HH:mm')} </span>
    </div>
)
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} delete={props.deleteFeedback} />;
}
class CustomerComplaint extends Component<{}, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  componentDidMount() {
    this.props.getComplaints();
    // console.log("prooooooooops",this.props)
    document.title = "DominosCRM | Customer Complaints";
    // if(this.props.data.length > 0)
  }
  render() {
    const {data} = this.props
    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Customer Feedbacks Not Found"
    };

    console.log("this.props.data",this.props.data)
    // if(this.props.data.length > 0){
    //   $('#example').DataTable({
    //   "buttons": [
    //     'copyHtml5',
    //     'excelHtml5',
    //     'csvHtml5',
    //     'pdfHtml5'
    //   ]
    // });  
    // }
    
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Customer Complaints Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body text-capitalize">
                       

                      {this.props.data && 
                      <div className="card-body">
                            <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} hover>
                                {/* <TableHeaderColumn dataField='customer_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn> */}
                                <TableHeaderColumn dataField='name' csvHeader='Name' width='100' columnTitle dataSort={true} isKey>Name</TableHeaderColumn>
                                <TableHeaderColumn tdStyle={{textTransform: 'none'}} dataField='email_address' csvHeader='Email' width='100' columnTitle>Email</TableHeaderColumn>
                                <TableHeaderColumn dataField='phone_number' csvHeader='Phone' width='100' columnTitle>Phone</TableHeaderColumn>
                                <TableHeaderColumn dataField='type' csvHeader='Type' width='100' columnTitle>Type</TableHeaderColumn>
                                <TableHeaderColumn dataField='receipt_number' csvHeader='Receipt Number' width='100' columnTitle>Receipt Number</TableHeaderColumn>
                                <TableHeaderColumn dataField='date_created' dataFormat={dateFormater} csvHeader='Complaint Date' width='130' columnTitle>Complaint Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='resolved_date' dataFormat={dateFormater} csvHeader='Resolve Date' width='130' columnTitle>Resolve Date</TableHeaderColumn>
                                <TableHeaderColumn dataField='action' width='100' dataFormat={responseFormatter} export={false}>Complaint Response</TableHeaderColumn>
                                <TableHeaderColumn dataField='status' width='100' dataFormat={statusFormatter} export={false}>Status</TableHeaderColumn>
                                <TableHeaderColumn dataField='action' width='70' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                      
                            </BootstrapTable>
                        </div>}
                        {/* {this.props.data
                        && <table
                          id=""
                          className="table table-striped table-bordered table-sm row-border hover mb-5"
                        >
                          <thead>
                              <th>Menu</th>
                              <th>Item</th>
                              <th>Name</th>
                              <th>Email</th>
                          </thead>
                          <tbody>
                            {this.props.data && this.props.data.map((data: any) => (
                              <tr>
                                <td>{data.menu_name}</td>
                                <td>{data.item_name}</td>
                                <td>{data.login_name}</td>
                                <td>{data.email_address}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  console.log("state",state);
  return {
    data: state.customerComplaint.complaintData,
    reasonsList: state.customerComplaint.complaintReasonsList
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    // logoutUser: function () {
    //   dispatch(logoutUser());
    // },
    // feedbackList: function () {
    //   dispatch(feedbackList());
    // },
    // deleteFeedback: function (id: any) {
    //   dispatch(deleteFeedback(id));
    // },
    
    getComplaints: function () {
      dispatch(getComplaints());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerComplaint);
