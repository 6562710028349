import Api from '../../components/Api';
import jwt, { decode } from 'jsonwebtoken'
import { toast } from 'react-toastify';
import { COMPLAINT_REASONS, CUSTOMER_COMPLAINTS } from './customerComplaintType';

//get customer complaints list
export const getComplaints = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/call-portal/feedbacks', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log(response)
                if (response.data.success) {
                    dispatch({
                        type: CUSTOMER_COMPLAINTS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const complaintReasonsList = () => {
return function (dispatch: any) {
    let token: any = localStorage.getItem('token');
    Api.post('/reasons/reasonByType', {
        type: 'complaint'
    })
        .then((response) => {
            if (response.data.success) {
                dispatch({
                    type: COMPLAINT_REASONS,
                    payload: response.data.successResponse
                })
            }
        }).catch(err => {
            if (err.response) {
                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
}
}




