import { GET_PROFILE,COUNTRY_LIST } from '../actions/userType'
const initialState: any = {
    userData: {},
    countries:[]||""
}

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PROFILE: return {
            ...state,
            userData: action.payload
        }
        case COUNTRY_LIST:return {
            ...state,
            countries:action.payload
        }
        default: return state;
    }
}
export default userReducer;