import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class Footer extends Component<{}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
    }
    render() {
        return (
            <footer className="main-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <p> &copy; Simplex 2020 - {new Date().getFullYear()} All rights reserved</p>
                        </div>
                        <div className="col-sm-6 text-right">
                            <p>
                                Powered By{" "}
                                <a style={{ textDecoration: "none",color:"white" }} className="external" href="https://simplexts.net/" target="/blank">
                                    SimpleX Technology Solutions
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
};