import React, { Component } from 'react';
import { connect } from 'react-redux'
import { countryList,  logoutUser } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import { AddAgentProps, AddAgentState } from '../../interfaces/agent';
import { addAgent } from '../../redux/actions/agentAction';

class AddAgent extends Component<AddAgentProps, AddAgentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            city: "",
            phone: "",
            cnic: "",
            country: "",
            address: "",
            storeId: "",
            isValidEmail: true,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }
    componentDidMount() {
        document.title = "DominosCRM | Agents"
        this.props.countryList();
        //this.props.storesList();
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    isUserReady = () => {
        let { firstname, country, phone, address, isValidEmail } = this.state;
            return (firstname !== "" && phone!== "" && country !== "" && address !== "" && isValidEmail === true);
        }
    validateEmail(event: { target: { name: any; value: any; }; }) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(event.target.value) === false) {
            this.setState({ email: event.target.value, isValidEmail: false })
        } else {
            this.setState({ email: event.target.value, isValidEmail: true });
        }
    };

    handleSubmit = (event: any) => {
        let { firstname, lastname, email, password, phone, storeId, city, country, address, cnic } = this.state;
        let data: any = {
            first_name: firstname,
            last_name: lastname,
            user_name: lastname === '' ? firstname : firstname + " " + lastname,
            email_address: email,
            password:password,
            city: city,
            phone_number: phone,
            country_id: country,
            address: address,
            role_id: 8,
            status: 1
        }
        // if (roleId == 3 || roleId == 6) {
        //     data.store_id = storeId;
        // }
        this.props.addAgent(data);
    }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, countries } = this.props;
        let { isValidEmail, email } = this.state;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-agent" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Drivers Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/agents" className="text-primary">Agents</Link></li>
                                <li className="breadcrumb-item active">Add Agent</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">First Name <span className="text-danger">*</span></label>
                                                            <input id="firstname" type="text" name="firstname" value={this.state.firstname} required data-msg="Please enter first name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Last Name</label>
                                                            <input id="lastname" type="text" name="lastname" value={this.state.lastname} required data-msg="Please enter last name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Email <span className="text-danger">*</span></label>
                                                            <input id="email" type="email" name="email" required data-msg="Please enter email" className="input-material" onChange={this.validateEmail} />
                                                            {(isValidEmail == false && email !== "") &&
                                                                <small className="form-text text-danger">email is not correct</small>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Password <span className="text-danger">*</span></label>
                                                            <input id="password" type="password" name="password" value={this.state.password} required data-msg="Please enter Password" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Phone Number <span className="text-danger">*</span></label>
                                                            <input type="text" name="phone" value={this.state.phone} required data-msg="Please enter Phone Number" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Country</label>
                                                            <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value="">Select Country</option>
                                                                {countries &&
                                                                    countries.map((country: any, index: any) => (
                                                                        <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                                <div className="row">
                                                <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">City</label>
                                                            <input id="city" type="text" name="city" value={this.state.city} required data-msg="Please enter city" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                             
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Address <span className="text-danger">*</span></label>
                                                            <input id="address" type="text" name="address" value={this.state.address} required data-msg="Please enter address" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isUserReady()} onClick={this.handleSubmit}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        // message: state.setting.message,
        // isInserted: state.setting.isInserted,
        countries: state.user.countries,
        // stores: state.order.stores
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addAgent: function (data: any) {
            dispatch(addAgent(data));
        },
        countryList: function () {
            dispatch(countryList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAgent);