import Api from '../../components/Api';
import { TIMER_START } from './customerType';
import { START_CLOCK_TIMER } from './headerType';
import jwt, { decode } from 'jsonwebtoken'
import { toast } from 'react-toastify';

let timer: any;
export const StartClockTimer = (time: any) => {
let countplus = time;
    return function (dispatch: any) {
    timer = setInterval(() => { 
        countplus = countplus + 1;
        dispatch({
            type: TIMER_START,
            CounterIncrement: countplus ,
          });
    }, 1000);
    };
  };




  export const stopTimer = () => {
    console.log("stop timer", timer)
    return function (dispatch: any) {
        dispatch({
            type: TIMER_START,
            CounterIncrement: 0,
          });
        clearInterval(timer)
    }
}

export const saveCallrecord = (data: any) => {

  return async (dispatch: any) => {
    let token: any = await localStorage.getItem('token');
    let decoded: any = jwt.decode(token);
    console.log(decoded)
    data.user_id = decoded.user_id;
    console.log(data)
    Api.post(`call-portal/add_agent_history`,data, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then((response) => {
        if (response.data.success) {
          
          toast.error("Order Time Stopped! ", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })          
        }
      }).catch(err => {
        if (err.response) {
          console.log(err.response.data.message)
        } else {
          alert(err.message)
        }
      });
  }
}