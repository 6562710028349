export const STORES_LIST = "STORES_LIST"
export const ADD_STORE = "ADD_STORE"
export const EDIT_STORE = "EDIT_STORE"
export const BRANDS_LIST = "BRANDS_LIST"
export const CHANNEL_LIST = "CHANNEL_LIST"
export const TYPES_LIST = "TYPES_LIST"
export const AREAS_LIST = "AREAS_LIST"
export const COUNTRY_LIST = "COUNTRY_LIST"
export const GET_BACKUP_STORES = "GET_BACKUP_STORES"
export const BUSINESS_TYPE_LIST = "BUSINESS_TYPE_LIST"
export const UPDATE_DELIVERY_TIME= "UPDATE_DELIVERY_TIME"
export const STATUS_CHANGE_REASONS="STATUS_CHANGE_REASONS"
export const UNRESOLVED_ORDER_LENGTH="UNRESOLVED_ORDER_LENGTH"
