import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import Login from './pages/login'
import Forgot from './pages/forgotpass'
import Home from './pages/home'
import Profile from './pages/profile'
import CustomerDetail from './pages/customerDetail/customerDetail'
import CustomerComplaint from './pages/customer/customer-complaints'
import Menu from './pages/menu/menu'
import Cart from './pages/cart/cart'
import Orders from './pages/order/order'
import Stores from './pages/stores/stores';
import Agents from './pages/agents/agents';
import AddAgent from './pages/agents/add-agent';
import EditAgent from './pages/agents/edit-agent';
import AgentTargets from './pages/settings/agentTargets/agent-targets';
import jwt from 'jsonwebtoken';
import foodpandaOrder from './pages/foodpandaOrder/foodpandaOrder';

const Notfound = () => <h1 className="text-center">Not found</h1>
function App() {
  let tokendata: any = jwt.decode(localStorage.token)
  let features: any = {};
  if (localStorage.token) {
    features = tokendata.features ? JSON.parse(tokendata.features) : tokendata.features;
  }
  return (
    // forceRefresh is used to force React Router to reload the page on every navigation
    <div className="App">
      < Provider store={store} >
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot" component={Forgot} />
            {(features && features.feature20 && features.feature20.permission == 1) && <Route exact path="/dashboard" component={Home} />}
            <Route exact path="/profile" component={Profile} />
            {(features && features.feature21 && features.feature21.permission == 1) && <Route exact path="/customerDetail" component={CustomerDetail} />}
            {(features && features.feature22 && features.feature22.permission == 1) && <Route exact path="/agents" component={Agents} />}
            {(features && features.feature22 && features.feature22.permission == 1) && <Route exact path="/add-agent" component={AddAgent} />}
            {(features && features.feature22 && features.feature22.permission == 1) && <Route exact path="/edit-agent/:id" component={EditAgent} />}
            {(features && features.feature23 && features.feature23.permission == 1) && <Route exact path="/stores" component={Stores} />}
            {(features && features.feature24 && features.feature24.permission == 1) && <Route exact path="/complaints" component={CustomerComplaint} />}
            {(features && features.feature25 && features.feature25.permission == 1) && <Route exact path="/orders" component={Orders} />}
            {(features && features.feature26 && features.feature26.permission == 1) && <Route exact path="/menu" component={Menu} />}
            {(features && features.feature26 && features.feature26.permission == 1) && <Route exact path="/cart" component={Cart} />}
            {(features && features.feature27 && features.feature27.permission == 1) && <Route exact path="/agent-targets" component={AgentTargets} />}
            {(features && features.feature28 && features.feature28.permission == 1) && <Route exact path="/foodpanda-order" component={foodpandaOrder} />}
            <Route component={Notfound} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
