import { ACTIVE_AGENTS_LIST, AGENTS_TARGET_LIST } from "../actions/settingsType";

const initialState: any = {
    agent_target_list: "" || [],
    activeAgentList: "" || [],
    agent_stats: {},
}
const settingsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AGENTS_TARGET_LIST: return {
          ...state,
          agent_target_list: action.payload
      }   
      case ACTIVE_AGENTS_LIST: return {
        ...state,
        activeAgentList: action.payload
    }  
      default:
        return state;
    }
  };
export default settingsReducer;