import React, { useEffect, useRef, useState } from 'react'
import Timer from 'react-compound-timer'
import { connect, useDispatch } from 'react-redux';
import { aggregatorFailedOrdersList, aggregatorOrdersList } from '../../redux/actions/orderAction';
import { DEFUALT_PAGE_SIZE } from '../../client-config';

const ReactTimer = (props: any) => {
    const dispatch = useDispatch();
    const [timerKey, setTimerKey] = useState(0);

    const handleTimerComplete = () => {
        props.clearDateTime();
        if (props.selectedTab == "2") {
            props.aggregatorFailedOrdersList(null);
        } else if (props.selectedTab == "1") {
            dispatch({ type: "SET_PAGINATION", payload: { pageNum: 1, pSize: DEFUALT_PAGE_SIZE } })

            let obj = {
                days: props.days,
                pageNumber: 1,
                pageSize: DEFUALT_PAGE_SIZE
            };
            props.aggregatorOrdersList(obj, null, null);
        };

        props.clearData()
        setTimerKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        setTimerKey(prevKey => prevKey + 1); // restart timer for start end date
    }, [props.timerKey])

    return (
        <Timer
            key={timerKey}
            initialTime={30000}
            direction="backward"
            checkpoints={[{ time: 0, callback: handleTimerComplete }]}
        >
            {() => (
                <React.Fragment>
                    <strong className="text-bold" style={{ color: " #20A5D6", fontSize: "2em" }}>0<Timer.Minutes />:<Timer.Seconds /></strong>
                </React.Fragment>
            )}
        </Timer>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        aggregatorOrdersList: (obj: any, datetime: any, updateLoader?: any) => {
            dispatch(aggregatorOrdersList(obj, datetime, updateLoader));
        },
        aggregatorFailedOrdersList: (updateLoader?: any) => {
            dispatch(aggregatorFailedOrdersList(updateLoader));
        }
    }
}
export default connect(null, mapDispatchToProps)(ReactTimer);
