import Api from '../../components/Api';
import jwt, { decode } from 'jsonwebtoken'
import { AGENT_STATS, CANCEL_ORDER_REASONS, ORDERS_LIST, AGG_ORDERS_LIST, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, ORDER_TRANSFER_REASONS, UPDATE_LOADER, UPDATE_ORDER, UPDATE_TIMER, MANUAL_SYNC_ORDER, UPDATE_SELECTED_ROW, FILTERED_ORDERS, AGG_FAILED_ORDERS_LIST, XML_BY_ORDER_ID } from './orderType';
import { toast } from 'react-toastify';
import moment from 'moment';
import { DEFUALT_PAGE_SIZE } from '../../client-config';

export const ordersList = (obj:any,datetime?: any,updateLoader?:any) => {
    if(updateLoader) updateLoader(true)
    return function (dispatch: any) {
        if (datetime) {
            dispatch({ type:"SET_PAGINATION", payload:{ pageNum: 1, pSize: DEFUALT_PAGE_SIZE } })
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })


        let token: any = localStorage.getItem('token');
        Api.post('/call-portal/orders',obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })

                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        // failedIntegOrderCount: response.data.counterData ? response.data.counterData.failedIntgOrders : 0,
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        payload: response.data.total_orders_count
                    })
                    if(updateLoader) updateLoader(false);
                    localStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: ORDERS_LIST,
                        payload: []
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        payload: 0
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
                if(updateLoader) updateLoader(false);
            });
    }
}

export const aggregatorOrdersList = (obj:any,datetime?: any,updateLoader?:any) => {
    if(updateLoader) updateLoader(true)
    return function (dispatch: any) {
        if (datetime) {
            dispatch({ type:"SET_PAGINATION", payload:{ pageNum: 1, pSize: DEFUALT_PAGE_SIZE } })
            dispatch({
                type: UPDATE_LOADER,
                isLoading: true
            })
        }
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })

        let ordersData:any=[]
        let token: any = localStorage.getItem('token');
        Api.post('/call-portal/agg-orders',obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                    
                        if(obj.check_number==""){
                            ordersData.push(obj)
                        }
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })


                    dispatch({
                        type: AGG_ORDERS_LIST,
                        payload: response.data.successResponse,
                        failedIntegOrderCount:ordersData.length
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        payload: response.data.total_orders_count
                    })
                    if(updateLoader) updateLoader(false);
                    localStorage.setItem('prevLength', response.data.successResponse.length)
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: AGG_ORDERS_LIST,
                        payload: []
                    })
                    dispatch({
                        type: "TOTAL_ORDERS_COUNT",
                        payload: 0
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
                if(updateLoader) updateLoader(false);
            });
    }
}

export const orderStatusList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get('/admin/order_status', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let voidIndex = response.data.successResponse.findIndex((obj:any) => obj.order_status_code == 8)
                    let voidObj = response.data.successResponse[voidIndex]
                    let beignTakenIndex = response.data.successResponse.findIndex((obj:any) => obj.order_status_code == 7)
                    let beignTakenObj = response.data.successResponse[beignTakenIndex]
                    let badCustomerIndex = response.data.successResponse.findIndex((obj:any) => obj.order_status_code == 9)
                    let badCustomerObj = response.data.successResponse[badCustomerIndex]
                    let OrganizedStatuses = response.data.successResponse.filter((item:any) => (item.order_status_code !== 7 && item.order_status_code !== 8 && item.order_status_code !== 9))
                    OrganizedStatuses.push(beignTakenObj);
                    OrganizedStatuses.push(voidObj);
                    OrganizedStatuses.push(badCustomerObj);
                    dispatch({
                        type: ORDER_STATUS_LIST,
                        payload: OrganizedStatuses
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const cancelOrdersReasons = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'cancel order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CANCEL_ORDER_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const orderTransferReasons = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'transfer order'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDER_TRANSFER_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const filterOrdersList = (obj: any, updateLoader?: any, closeTimer?: any, startTimer?: any) => {
    return function (dispatch: any) {
        if (updateLoader) {
            updateLoader(true)
        }
        // closeTimer()
        dispatch({
            type: "CLEAR_ORDERS",
            payload: []
        })
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/status_filter_orders`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: response.data.successResponse,
                        emptyFilterOrder: response.data.successResponse.length > 0 ? false : true,
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0
                    })
                    if (startTimer) startTimer()
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    if (startTimer) startTimer()
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const filterFPOrdersList = (obj: any, updateLoader?: any, closeTimer?: any, startTimer?: any) => {
    return function (dispatch: any) {
        if (updateLoader) {
            updateLoader(true)
        }
        // closeTimer()
        dispatch({
            type: "CLEAR_ORDERS",
            payload: []
        })
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/status_filter_orders_fp`, obj, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    dispatch({
                        type: AGG_ORDERS_LIST,
                        payload: response.data.successResponse,
                        emptyFilterOrder: response.data.successResponse.length > 0 ? false : true,
                        currentPage: 0,
                        pageCount: 0,
                        numOfRows: 0,
                        failedIntegOrderCount:response.data.successResponse.length
                    })
                    if (startTimer) startTimer()
                }
            }).catch(err => {
                if (err.response) {
                    if (updateLoader) {
                        updateLoader(false)
                    }
                    dispatch({
                        type: AGG_ORDERS_LIST,
                        payload: [],
                        emptyFilterOrder: false,
                        failedIntegOrderCount:0
                    })
                    if (startTimer) startTimer()
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrdersByQueryFP = (query: any,data:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/call-portal/search_query_fp_orders/${query}`, {data }, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    let newOrder = 0;
                    let recieved = 0;
                    let readyOrder = 0;
                    let dispatched = 0;
                    let completed = 0;
                    let failedIntg = 0;
                    let ordersData: any = []
                    response.data.successResponse.map((obj: any) => {
                        if (obj) {
                            ordersData.push(obj)
                        }
                    })

                    for (let i = 0; i <ordersData.length; i++) {
                        const obj =ordersData[i];
                        if (obj.order_status_code === 1) {
                            newOrder += 1;
                        } if (obj.order_status_code === 2) {
                            recieved += 1;
                        } if (obj.order_status_code === 12) {
                            readyOrder += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 5) {
                            dispatched += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 11) {
                            completed += 1;
                        } if (obj.check_number === '') {
                            failedIntg += 1;
                        }
                    }
                   ordersData.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                   ordersData.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg =ordersData[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload:ordersData,
                        avg: avg,
                        newOrderCount: newOrder,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        dispatchOrderCount: dispatched,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: "AGG_ORDERS_LIST",
                        payload: [],
                        avg: 0,
                        dayVal: "",
                        newOrderCount: 0,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        dispatchedCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateOrder = (id: any, data: any, history: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_ORDER,
            payload: true
        })
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/agg_order_approval/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(`${response.data.successResponse}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                    let obj = {
                        days: 0,
                        pageNumber:1,
                        pageSize:DEFUALT_PAGE_SIZE
                    }
                    dispatch(aggregatorOrdersList(obj))
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please select the driver";
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch({
                        type: UPDATE_ORDER,
                        payload: false
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const aggOrderTransfer = (id: any, data: any, closeModal: any,updateLoaderState:any,updateLoader:any,startTimer:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/order_transfer/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Order Transfered Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    closeModal.click();
                    startTimer &&     startTimer()
                    updateLoaderState &&  updateLoaderState(false)
                    let obj = {
                        days: 0,
                        pageNumber:1,
                        pageSize:DEFUALT_PAGE_SIZE
                    }
                    dispatch(aggregatorOrdersList(obj))
                } else {
                    closeModal.click();
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    updateLoaderState && updateLoaderState(false)
                }
            }).catch(err => {
                if (err.response) {
                    closeModal.click();
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    updateLoaderState && updateLoaderState(false)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const orderTransfer = (id: any, data: any, closeModal: any,updateLoaderState:any,updateLoader:any,startTimer:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.put(`/admin/order_transfer/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Order Transfered Succesfully!", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    closeModal.click();
                    startTimer &&     startTimer()
                    updateLoaderState &&  updateLoaderState(false)
                    let obj = {
                        days: 0,
                        pageNumber: 1,
                        pageSize: DEFUALT_PAGE_SIZE
                    }
                    dispatch(ordersList(obj,null,updateLoader))
                } else {
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    updateLoaderState && updateLoaderState(false)
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    updateLoaderState && updateLoaderState(false)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrder = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/callportal_search_order/${orderId}`,{
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const searchOrderByfoodPandaId = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/search_order_fp_id/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
            if (response.data.success) {
                let ordersData: any = []
                response.data.successResponse.map((obj: any) => {
                    // if (obj.order_status_code == 11) {
                        ordersData.push(obj)
                    // }
                })
                ordersData && ordersData.length > 0 && ordersData.map((obj: any) => {
                    let mintDiff: any = "";
                    if (obj.future_status == 1) {
                        let futureDay = moment(obj.future_date).local().startOf('day');
                        let currentDay = moment(new Date()).startOf('day');
                        const currentTime = moment().format('HH:mm:ss');
                        const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                        if (futureDay.isSame(currentDay)) {
                            mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                            if (mintDiff >= 0 && mintDiff <= 60) {
                                obj.orderStatus = "now";
                            } else if (mintDiff > 60) {
                                obj.orderStatus = "future";
                            } else {
                                obj.orderStatus = "now";
                            }
                        } else {
                            let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                            let currentDate = moment().format('YYYY-MM-DD');
                            if (moment(currentDate).isAfter(futureDate, 'day')) {
                                obj.orderStatus = "now";
                            } else {
                                obj.orderStatus = "future";
                            }
                        }
                    } else {
                        obj.orderStatus = "now";
                    }
                })

                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: ordersData
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: response.data.total_orders_count
                })
                localStorage.setItem('prevLength', response.data.successResponse.length)
            }
        }).catch(err => {
            if (err.response) {
                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: []
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: 0
                })

                console.log(err.response.data.message)
            } else {
                alert(err.message)
            }
        });
    }
}
export const searchFPordersByOrderID = (orderId: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/admin/search_fp_order_order_id/${orderId}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((response) => {
            if (response.data.success) {
                let ordersData: any = []
                response.data.successResponse.map((obj: any) => {
                    // if (obj.order_status_code == 11) {
                        ordersData.push(obj)
                    // }
                })
                ordersData && ordersData.length > 0 && ordersData.map((obj: any) => {
                    let mintDiff: any = "";
                    if (obj.future_status == 1) {
                        let futureDay = moment(obj.future_date).local().startOf('day');
                        let currentDay = moment(new Date()).startOf('day');
                        const currentTime = moment().format('HH:mm:ss');
                        const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                        if (futureDay.isSame(currentDay)) {
                            mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                            if (mintDiff >= 0 && mintDiff <= 60) {
                                obj.orderStatus = "now";
                            } else if (mintDiff > 60) {
                                obj.orderStatus = "future";
                            } else {
                                obj.orderStatus = "now";
                            }
                        } else {
                            let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                            let currentDate = moment().format('YYYY-MM-DD');
                            if (moment(currentDate).isAfter(futureDate, 'day')) {
                                obj.orderStatus = "now";
                            } else {
                                obj.orderStatus = "future";
                            }
                        }
                    } else {
                        obj.orderStatus = "now";
                    }
                })

                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: ordersData,
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: response.data.total_orders_count
                })
                localStorage.setItem('prevLength', response.data.successResponse.length)
            }
        }).catch(err => {
            if (err.response) {
                dispatch({
                    type: "AGG_ORDERS_LIST",
                    payload: []
                })
                dispatch({
                    type: "TOTAL_ORDERS_COUNT",
                    payload: 0
                })
            } else {
                alert(err.message)
            }
        });
    }
}


export const getAgentStats = (days:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        let decoded: any = jwt.decode(token);
        console.log("calling decode",decoded)
        Api.post(`call-portal/agent_stats/${decoded.user_id}`,days, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: AGENT_STATS,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const showorderItems = (order_id: any, orders: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        if (order_id === "") {
            dispatch({
                type: ORDER_ITEMS_LIST,
                payload: []
            })
        } else {
            Api.get(`/admin/order_items/${order_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        let order = orders.find((obj: any) => {
                            return obj.order_id == order_id;
                        })
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: response.data.successResponse,
                            orderDetail: order
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ORDER_ITEMS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}

export const getOrderXML = (order_id: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
      
        return Api.get(`/admin/xml_by_order_id/${order_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: XML_BY_ORDER_ID,
                        payload: response.data.successResponse,
                    });
                    dispatch(updateSelectedRow(response.data.successResponse));
                }
                return response.data.successResponse;
            })
            .catch((err) => {
                if (err.response) {
                    dispatch({
                        type: XML_BY_ORDER_ID,
                        payload: []
                    });
                    console.log(err.response.data.message);
                } else {
                    alert(err.message);
                }
                return null;
            });
    };
};


export const newmanualSyncOrder = (id: any, data: any, closeModal: any,updateLoaderState?:any) => {
    return async  function (dispatch: any) {
        dispatch({
            type: MANUAL_SYNC_ORDER,
            payload: true
        })

        let xmlData:any = await dispatch(getOrderXML(id));
        console.log(xmlData,"xmlData");
        
        if(updateLoaderState) updateLoaderState(true)
        let token: any = localStorage.getItem('token');
        data.user_info = jwt.decode(token);
        if(xmlData !=null){
            Api.post(`/admin/dumpIntegOrdersManually/${id}`, { pos_xml: xmlData.pos_xml }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            })
                .then((response) => {
                    if (response.data.success) {
                        closeModal.click();
                        toast.info(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                        dispatch(ordersList({ days: 0 }))
                    } else {
                        toast.error(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                        dispatch({
                            type: MANUAL_SYNC_ORDER,
                            payload: false
                        })
                        if(updateLoaderState) updateLoaderState(false)
                    }
                }).catch(err => {
                    dispatch({
                        type: MANUAL_SYNC_ORDER,
                        payload: false
                    })
                    if(updateLoaderState) updateLoaderState(false)
                });
        }else{
            dispatch({
                type: MANUAL_SYNC_ORDER,
                payload: false
            })
            if(updateLoaderState) updateLoaderState(false)
            toast.error(`Order Faild`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
        }
        
    }
}
export const updateSelectedRow = (row: any) => {
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_SELECTED_ROW,
            payload: row
        })
    }
};

export const searchOrdersByQuery = (query: any,data:any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.post(`/call-portal/search_query_orders/${query}`, {data }, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    let newOrder = 0;
                    let recieved = 0;
                    let readyOrder = 0;
                    let dispatched = 0;
                    let completed = 0;
                    let failedIntg = 0;

                    for (let i = 0; i < response.data.successResponse.length; i++) {
                        const obj = response.data.successResponse[i];
                        if (obj.order_status_code === 1) {
                            newOrder += 1;
                        } if (obj.order_status_code === 2) {
                            recieved += 1;
                        } if (obj.order_status_code === 12) {
                            readyOrder += 1;
                        } if (obj.order_status_code === 3 || obj.order_status_code === 5) {
                            dispatched += 1;
                        } if (obj.order_status_code === 4 || obj.order_status_code === 11) {
                            completed += 1;
                        } if (obj.check_number === '') {
                            failedIntg += 1;
                        }
                    }
                    response.data.successResponse.forEach((element: any) => {
                        element.date_created = moment(element.date_created).local().format('YYYY-MM-DD HH:mm');
                    })
                    response.data.successResponse.map((obj: any) => {
                        let mintDiff: any = "";
                        if (obj.future_status == 1) {
                            let futureDay = moment(obj.future_date).local().startOf('day');
                            let currentDay = moment(new Date()).startOf('day');
                            const currentTime = moment().format('HH:mm:ss');
                            const futureTime = moment(obj.future_date).local().format('HH:mm:ss');
                            if (futureDay.isSame(currentDay)) {
                                mintDiff = moment(futureTime, 'HH:mm').diff(moment(currentTime, 'HH:mm'), 'minutes');
                                if (mintDiff >= 0 && mintDiff <= 60) {
                                    obj.orderStatus = "now";
                                } else if (mintDiff > 60) {
                                    obj.orderStatus = "future";
                                } else {
                                    obj.orderStatus = "now";
                                }
                            } else {
                                let futureDate = moment(obj.future_date).local().format('YYYY-MM-DD')
                                let currentDate = moment().format('YYYY-MM-DD');
                                if (moment(currentDate).isAfter(futureDate, 'day')) {
                                    obj.orderStatus = "now";
                                } else {
                                    obj.orderStatus = "future";
                                }
                            }
                        } else {
                            obj.orderStatus = "now";
                        }
                    })
                    let avg = response.data.successResponse[0].avg_delivery_time;
                    dispatch({
                        type: FILTERED_ORDERS,
                        payload: [],
                        emptyFilterOrder: false
                    })
                    dispatch({
                        type: ORDERS_LIST,
                        payload: response.data.successResponse,
                        avg: avg,
                        newOrderCount: newOrder,
                        dayVal: "",
                        recievedOrderCount: recieved,
                        readyOrderCount: readyOrder,
                        dispatchOrderCount: dispatched,
                        completedOrderCount: completed,
                        failedIntegOrderCount: failedIntg,
                    })
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace("_", " ");
                    }
                    dispatch({
                        type: ORDERS_LIST,
                        payload: [],
                        avg: 0,
                        dayVal: "",
                        newOrderCount: 0,
                        recievedOrderCount: 0,
                        readyOrderCount: 0,
                        dispatchedCount: 0,
                        completedOrderCount: 0,
                        failedIntegOrderCount: 0
                    })
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

//FP Failed Orders
export const aggregatorFailedOrdersList = (updateLoader?: any) => {
    if (updateLoader) updateLoader(true)
    return function (dispatch: any) {
        dispatch({
            type: UPDATE_TIMER,
            isTimerUpdate: true
        })
        let token: any = localStorage.getItem('token');
        Api.post('/call-portal/agg-failed-orders', { days: 0 }, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: AGG_FAILED_ORDERS_LIST,
                        payload: response.data.successResponse
                    })
                    if (updateLoader) updateLoader(false);
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: AGG_FAILED_ORDERS_LIST,
                        payload: []
                    })
                } else {
                    alert(err.message)
                }
                if (updateLoader) updateLoader(false);
            });
    }
}
export const manualSyncFPFailedOrder = (id: any, data: any, closeModal: any,updateLoaderState?:any) => {
    return function (dispatch: any) {
        dispatch({
            type: MANUAL_SYNC_ORDER,
            payload: true
        })
        if (updateLoaderState) updateLoaderState(true)
        let token: any = localStorage.getItem('token');
        Api.post(`/admin/dumpFPOrdersManually/${id}`,data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    closeModal.click();
                    toast.info(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 });
                    dispatch(aggregatorFailedOrdersList(null))
                } else {
                    toast.error(`${response.data.message}`, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 10000 });
                    dispatch({
                        type: MANUAL_SYNC_ORDER,
                        payload: false
                    })
                    if (updateLoaderState) updateLoaderState(false)
                }
            }).catch(err => {
                dispatch({
                    type: MANUAL_SYNC_ORDER,
                    payload: false
                })
                if (updateLoaderState) updateLoaderState(false)
            });
    }
}