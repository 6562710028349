import jwt_decode from "jwt-decode";
import Api from '../../components/Api';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AGENTS_LIST, GET_AGENT } from "./agentType";
toast.configure();


export const agentsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        var store: any = jwt_decode(token);
        Api.get(`/call-portal/agents/`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let Agentsarray: any = []
                    Agentsarray = response.data.successResponse
                    Agentsarray.sort(function(a:any,b:any){
                        return b.status - a.status  
                    })
                    console.log("sorted array",Agentsarray)
                    dispatch({
                        type: AGENTS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getAgent = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/call-portal/agent/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("successResponse",response.data)
                    dispatch({
                        type: GET_AGENT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const addAgent = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        // var store: any = jwt_decode(token);
        // data.store_id = store.store_id;
        data.user_info = jwt_decode(token);
        Api.post('/call-portal/add_agent', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/agents";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Agent Already Exist"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const editAgent = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/call-portal/edit_agent/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/agents";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const delAgent = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.delete(`/call-portal/del_agent/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/agents";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const blockunblockuser = (id: any, status: any,email_address?:any) => {
    return function (dispatch: any) {
        let is_active;
        if (status == 0) {
            is_active = 1
        } else if (status == 1) {
            is_active = 0
        }
        let token: any = localStorage.getItem('token');
        let data = {
            status: is_active,
            email_address:email_address,
            user_info: jwt_decode(token)
        }
        Api.put(`/call-portal/agent_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/agents"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
