import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser } from '../../redux'
import { StoreProps, StoreStates } from '../../interfaces/store';
import { blockunblockstore, statusChangeReasons, storesList, unresolvedordersList, UnsuspendStore, UpdateDeliveryTime } from './../../redux/actions/storeAction';

class ActionFormatter extends Component<{ row: any, itemStatusReasons:any,unresolvedStoreOrder:any, unresolvedOrderLength:(obj:any) => {}, blockunblock: (id: any, is_active: any,data:any) => {},UnsuspendStore: (id: any, data: any) => {}  }, {[x: number]: any,statusReason:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
        statusReason: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleBlockUnblock = (id: any, is_active: any) => {
        //  localStorage.ge
        let { row} = this.props;
        let { statusReason } = this.state
        let token: any = localStorage.getItem('token')
        let userInfo:any = jwt.decode(token);
        let data: any = {
            role: userInfo.role,
            user_name: userInfo.first_name + " " + userInfo.last_name,
            reason: statusReason,
            activity_type: 'Stores',
            activity_name: row.store_name,
            status: is_active == 0 ? "Unblock" : "Block"
        }
        this.props.blockunblock(id, is_active,data);
    };
    getOrdersLength = (store_id:any) => {
        console.log("call for orders length")
        let obj = {
            store_id: store_id,
            days:1
        }
        this.props.unresolvedOrderLength(obj)
    }
    handleUnsuspendStore = (id: any, is_active: any) => {
        //  localStorage.ge
        let { row} = this.props;
        let obj = {
            is_suspend: 0,
            is_active: 1
        }
        this.props.UnsuspendStore(id,obj)
    };
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { row,itemStatusReasons,unresolvedStoreOrder } = this.props
        return (
            <div>
                {!row.is_suspend && 
                <Link to="#" title={row.is_active === 0 ? "Activate" : "Deactivate"} data-toggle="modal" data-target={`#blockunblock${row.store_id}`} className={row.is_active === 0 ? "btn btn-outline-success mx-2" : "btn btn-outline-danger mx-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></Link>
                }
                {row.is_suspend == 1 && 
                <Link to="#" onClick={ ()=>this.getOrdersLength(row.store_id)} title={row.is_suspend === 1 ? "Reinstate" : "Suspend"} data-toggle="modal" data-target={`#suspend${row.store_id}`} className="btn btn-outline-success mx-2"><i className="fa fa-refresh"></i></Link>
                }
                {/* <Link title="Edit Store" className="btn btn-outline-primary" to={`/edit-store/${row.store_id}`}><i className="fa fa-edit"></i></Link> */}
                {/* <!-- B/U Modal--> */}
                <div id={`blockunblock${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "Activate" : "Deactivate"} Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                    <p>Are you sure,you want to {row.is_active === 0 ? "Activate" : "Deactivate"} this store?</p>
                                    </div>
                                </div>
                                {row.is_active === 1 &&
                                <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="statusReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {itemStatusReasons &&
                                                    itemStatusReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.reason}>{reason.reason}</option>
                                                    ))
                                                }   
                                            </select>

                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.store_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={`suspend${row.store_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Reinstate Store</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        {unresolvedStoreOrder.length>0 ?
                                        <p>Store has some unresolved orders pending. </p>
                                        :
                                        <p>Are you sure,you want to reinstate this store?</p>
                                    }
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                            {unresolvedStoreOrder.length>0 ?
                                        <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                        :
                                        <>
                                        <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                        <button onClick={() => this.handleUnsuspendStore(row.store_id, row.is_suspend)} className="btn btn-primary">Confirm</button>        
                                        </>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} unresolvedStoreOrder={props.unresolvedStoreOrder} unresolvedOrderLength={props.unresolvedordersList} UnsuspendStore={props.UnsuspendStore} blockunblock={props.blockunblockstore} itemStatusReasons={props.itemStatusReasons}/>
    );
}

class TimeFormatter extends Component<{ row: any, blockunblock: (id: any, is_active: any) => {},UpdateDeliveryTime: (id: any, delivery_time: any) => {}  }, {[x: number]: any,delivery_time:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            delivery_time: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event: { target: { name: any; value: any } }) {
        this.setState({
          [event.target.name]: event.target.value
        });
      }
    saveDeliveryTime = (id: any, delivery_time: any) => {
        this.props.UpdateDeliveryTime(id, delivery_time, this.props.row.store_name);
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <div>
                <input style={{height: '25px'}} name="delivery_time" className="form-control" defaultValue={row.expected_delivery_time} onChange={this.handleInputChange}/>
                <span className="badge badge-pill badge-info text-capitalize mt-2" style={{cursor:'pointer'}} onClick={() => this.saveDeliveryTime(row.store_id,this.state.delivery_time)}>save</span>                
                </div>                
            </div>
        )
    }
}
function deliveryFormatter(cell: any, row: any, props: any) {
    return (
        <TimeFormatter row={row} UpdateDeliveryTime={props.UpdateDeliveryTime} />
    );
}
class Stores extends Component<StoreProps, StoreStates> {
    constructor(readonly props: any) {
        super(props);
        this.state= {
        statusReason: "",
        }
    }
    componentDidMount() {
        this.props.statusChangeReasons();
        this.props.storesList()
        document.title = "DominosCRM | Stores"
    }
    // createInsertModal = (onModalClose: any, onSave: any, columns: any, validateState: any, ignoreEditable: any) => {
    //     const attr = {
    //         onModalClose, onSave, columns, validateState, ignoreEditable
    //     };
    //     return (
    //         <CustomInsertModal {...attr} />
    //     );
    // }
    render() {
        if (localStorage.token) {
            jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-store" /> },
            noDataText: 'Stores Not Found'
        };
        // const selectRowProp: any = {
        //     mode: 'checkbox',
        //     bgColor: '#4ac1ec',
        //     columnWidth: '40px'
        // };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                            <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} csvFileName='stores.csv' hover>
                                                    <TableHeaderColumn dataField='store_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='store_name' csvHeader='StoreName' width='100' columnTitle>Store Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='store_email' csvHeader='StoreEmail' width='100' columnTitle>Store Email</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='channel_name' csvHeader='Channel' width='100' columnTitle>Sales Channel</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='city' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='address' csvHeader='Address' width='100' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='branch_code' csvHeader='BranchCode' width='100' columnTitle>Branch ID</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='fiscal_pos_code' csvHeader='FiscalCode' width='100' columnTitle>Branch Code</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='expected_delivery_time' csvHeader='Address' width='100' dataFormat={deliveryFormatter} formatExtraData={this.props} columnTitle>Delivery Time</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='store_open_time' dataFormat={timeFormatter} csvHeader='OpeningTime' width='100' columnTitle>Opening Time</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='store_close_time' dataFormat={timeFormatter} csvHeader='ClosingTime' width='100' columnTitle>Closing Time</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' width='80' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function timeFormatter(cell: any) {
    //convert the opening time 24 hour to 12 hour
    if(cell){
        let time = cell.split(":")
        let Hours = time[0]
        let AmOrPm = Hours >= 12 ? 'PM' : 'AM';
        Hours = (Hours % 12) || 12;
        let Finaltime = Hours + ":" + time[1] + " " + AmOrPm;
        return Finaltime;
    }
    return ""
}
const mapStateToProps = (state: any) => {
    return {
        data: state.store.data,
        itemStatusReasons: state.store.statusChangeReasons,
        unresolvedStoreOrder: state.store.unresolvedStoreOrder
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storesList: function () {
            dispatch(storesList())
        },
        blockunblockstore: function (id: any, is_active: any,data:any) {
            dispatch(blockunblockstore(id, is_active,data))
        },
        UpdateDeliveryTime: function (id:any, delivery_time: any, store_name: any) {
            dispatch(UpdateDeliveryTime(id, delivery_time, store_name))
        },
        statusChangeReasons: function () {
            dispatch(statusChangeReasons())
        },
        unresolvedordersList: function (obj: any) {
            dispatch(unresolvedordersList(obj))
        },
        UnsuspendStore: function (id:any, data:any){
            dispatch(UnsuspendStore(id, data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Stores);