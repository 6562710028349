export const ORDERS_LIST="ORDERS_LIST"
export const AGG_ORDERS_LIST="AGG_ORDERS_LIST"
export const AGENT_STATS="AGENT_STATS"
export const XML_BY_ORDER_ID = "XML_BY_ORDER_ID"
export const ORDER_ITEMS_LIST="ORDER_ITEMS_LIST" 
export const ORDER_STATUS_LIST="ORDER_STATUS_LIST"
export const CANCEL_ORDER_REASONS="CANCEL_ORDER_REASONS"
export const ORDER_TRANSFER_REASONS="ORDER_TRANSFER_REASONS"
export const UPDATE_ORDER="UPDATE_ORDER"
export const UPDATE_LOADER = "UPDATE_LOADER"
export const UPDATE_TIMER = "UPDATE_TIMER"
export const FILTERED_ORDERS = "FILTERED_ORDERS"
export const MANUAL_SYNC_ORDER="MANUAL_SYNC_ORDER"
export const UPDATE_SELECTED_ROW="UPDATE_SELECTED_ROW"
export const AGG_FAILED_ORDERS_LIST="AGG_FAILED_ORDERS_LIST"