import React, { Component } from "react";
import Sidebar from "../../components/sidebar";
import Topbar from "../../components/topbar";
import Footer from "../../components/footer/main";
import "../order/order.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import beautify from "xml-beautifier";
import _ from "lodash";
import { OrderProps, OrderState } from "../../interfaces/order";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  getCart,
  getStores,
  getTaxValue,
  logoutUser,
  saveCart
} from "../../redux";
import Complaint from '../customerDetail/complaint';
import Invoice from '../order/FbrInvoice/fbrInvoice';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { orderStatusList, aggregatorOrdersList, orderTransfer, orderTransferReasons, showorderItems, updateOrder, newmanualSyncOrder, searchOrderByfoodPandaId, searchFPordersByOrderID, updateSelectedRow, filterFPOrdersList, searchOrdersByQueryFP, aggregatorFailedOrdersList, manualSyncFPFailedOrder, getOrderXML, aggOrderTransfer } from "../../redux/actions/orderAction";
import moment from "moment";
import jwt from 'jsonwebtoken'
import { complaintReasonsList } from "../../redux/actions/customerComplaintAction";
import { DEFUALT_PAGE_SIZE, priceunit } from "../../client-config";
import { toast } from "react-toastify";
import Skelton from "../skeltonLoader";
import ReactTimer from "./timer"
import PaginationComp from "../../components/pagination";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
let selectedOrderId = 0;
let selectedStoreIdForOrderTransfer = 0;
// class ActionFormatter extends Component<{ row: any }, { selectedOrderId: any }> {
//   constructor(readonly props: any) {
//     super(props);
//     this.state = {
//       selectedOrderId: "",
//     }
//   }
//   showorderItems = (id: any) => {
//     let storeId: any = "";
//     let stateId: any = "";
//     this.props.data.showorderItems(id, this.props.data.orders);
//     storeId = this.props.data.orders.find((obj: any) => {
//       return obj.order_id == id;
//     }).store_id;
//     if (storeId && storeId !== "") {
//       stateId = this.props.data.stores && this.props.data.stores.find((obj: any) => {
//         return obj.store_id == storeId;
//       }).state_id;
//     }
  
//     this.setState({ selectedOrderId: id });
//   }
//   render() {
//     const { row, data } = this.props;
//     return (
//       <div>
//         {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
//         <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
//         {/* <!-- Modal--> */}
//         {/*Invoice Modal */}
//         <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
//           <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
//                 <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
//               </div>
//               <div className="modal-body">
//                 <Invoice data={data} orderId={this.state.selectedOrderId} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }
class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showorderItems = (id: any) => {
    this.props.data.showorderItems(id, this.props.data.orders);
    selectedOrderId = id
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
      </div>
    )
  }
}
class OrderEditor extends React.Component<{ data: any, row: any, }, { [x: number]: any, cancelReason: any, otherReason: any, orderstatus: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      orderstatus: "",
      cancelReason: "",
      otherReason: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ orderstatus: row.order_status_code })
  }
  handleSave = () => {

    const { row } = this.props
    const { orderstatus } = this.state;
    const data: any = {
      order_status_code: orderstatus,
      delivery_status: row.delivery_status,
    }
    if (orderstatus == row.order_status_code) {
      toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
    } else {
      this.props.data.updateOrder(row.order_id, data, this.props.data.history)
    }
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { data, row } = this.props;
    const { orderstatus } = this.state;
    return (
      <div>
        {(row.check_number == "" && row.order_status_code == 1) &&
          <div>
            <select
              style={{ height: "37px" }}
              name="orderstatus"
              value={orderstatus}
              onChange={this.handleInputChange}
              className="text-capitalize">
              <option key={0} value={''}>{'Select '}</option>
              {data.statusList &&
                data.statusList.map((status: any, index: any) => (
                  (status.order_status_code == 11 || status.order_status_code == 12) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                ))
              }
            </select>
            <br />
            <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>
          </div>
        }
      </div>
    );
  }
}
class XMLEditor extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title="View Pulse Response"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#ViewPulseResponse${row.order_id}`}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`ViewPulseResponse${row.order_id}`}
          data-backdrop='static'
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Pulse Response
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row" >
                  {row.failure_reason &&
                    <div className="form-group col-md-12">
                      <h5 id="txt">Pulse Integration Fail Reason</h5>
                      <textarea name="failure_reason" id="round2" disabled style={{ width: '100%', height: '50px' }}>
                        {row.failure_reason}
                      </textarea>
                    </div>
                  }
                  <div className="form-group col-md-12">
                    <h5 id="txt">Pulse Response XML</h5>
                    <textarea name="pos_response_xml" id="round2" disabled style={{ width: '100%', height: '300px' }}>
                      {row.pos_response_xml && beautify(row.pos_response_xml)}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class ReqXMLEditor extends Component<{ row: any }, { [x: number]: any, editXml: any, pos_request_xml: any, order_id: any }> {
  closeModal: any;
  constructor(readonly props: any) {
    super(props);
    this.state = {
      editXml: false,
      pos_request_xml: this.props.row.pos_xml,
      order_id: ""
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ order_id: row.order_id })
    console.log("row", this.props)
  }
  handleSaveSync = () => {
    const { row } = this.props
    const { order_id } = this.state;
    const data = {
      order_id: order_id ? order_id : "",
      pos_xml: this.state.pos_request_xml
    }
    this.props.data.newmanualSyncOrder(row.order_id, data, this.closeModal);
    this.setState({ editXml: false })
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title="View Pulse Request"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#ViewPulseReq${row.order_id}`}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`ViewPulseReq${row.order_id}`}
          data-backdrop='static'
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Pulse Request Body
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className='d-flex justify-content-between mb-2'>
                  {this.state.pos_request_xml && this.state.pos_request_xml !== "" &&
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      onClick={() => this.setState({ editXml: true })}
                      disabled={row.check_number === '' ? false : true}
                    >
                      <i className="fa fa-edit"></i> Update XML
                    </button>
                  }
                  <button
                    type="button"
                    className="btn btn-sm btn-info"
                    disabled={(this.state.editXml && row.check_number === '') ? false : true}
                    onClick={this.handleSaveSync}
                  >
                    <i className="fa fa-undo"></i> Re-Push
                  </button>
                </div>
                <div className="form-row" >
                  <div className="form-group col-md-12">
                    <textarea name="pos_request_xml" id="round2" disabled={this.state.editXml ? false : true} style={{ width: '100%', height: '300px' }} onChange={this.handleInputChange}>
                      {this.state.pos_request_xml}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
              <div style={{ display: "none" }}>
                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
// class ManualSyncButton extends Component<{ row: any }, { [x: number]: any, pos_request_xml: any, order_id: any, loader: any }> {
//   constructor(readonly props: any) {
//     super(props);
//     this.state = {
//       pos_request_xml: this.props.row.pos_xml,
//       order_id: "",
//       loader: false
//     }
//     this.handleInputChange = this.handleInputChange.bind(this);
//   }
//   componentDidMount() {
//     const { row } = this.props
//     this.setState({ order_id: row.order_id })
//   }
//   handleSaveSync = () => {
//     const { row } = this.props
//     const { order_id } = this.state;
//     const data = {
//       order_id: order_id ? order_id : "",
//       pos_xml: this.state.pos_request_xml
//     }
//     this.props.data.newmanualSyncOrder(row.order_id, data, null, this.updateLoaderState);
//   }
//   handleInputChange(event: { target: { name: any; value: any; }; }) {
//     this.setState({
//       [event.target.name]: event.target.value
//     });
//   }
//   updateLoaderState = (loaderValue: any) => {
//     this.setState({ loader: loaderValue });
//   }
//   render() {
//     const { row } = this.props;
//     return (
//       <div>
//         {/* this.props.data.clickmenuSync */}
//         {(this.state.loader) ?
//           <button
//             type="button"
//             title="Re-Push"
//             className="btn btn-sm btn-outline-primary"
//             disabled={this.props.data.clickmenuSync}
//           >
//             <Loader type="TailSpin" color="red" height={30} width={30} />
//           </button> :
//           <button
//             type="button"
//             title="Re-Push"
//             className="btn btn-sm btn-outline-primary"
//             disabled={(row.check_number === '') ? false : true}
//             onClick={this.handleSaveSync}
//           >
//             <i className="fa fa-undo"></i>
//           </button>
//         }
//       </div >
//     );
//   }
// }
class ManualSyncButton extends Component<{ row: any }, { [x: number]: any, pos_request_xml: any, order_id: any, loader: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      // pos_request_xml: this.props.row.pos_xml,
      pos_request_xml:"",
      order_id: "",
      loader: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
 
  componentDidMount() {
    const { row } = this.props
    this.setState({ order_id: row.order_id })
  }
  
  handleSaveSync = () => {
    const { row } = this.props
    const { order_id } = this.state;
    const data = {
      order_id: order_id ? order_id : "",
      // pos_xml: this.state.pos_request_xml
      // pos_xml: this.props.data.orderXML.pos_xml
    }
    console.log(this.props.data.orderXML,"Respomnse");
    
    this.props.data.newmanualSyncOrder(row.order_id, data, null, this.updateLoaderState);
  }
  // handleCLick=()=>{
  //   const { row } = this.props
  //   this.props.data.getOrderXML(row.order_id)

  //   setTimeout(()=>{
  //     this.handleSaveSync()
  //   },500)

  // }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  updateLoaderState = (loaderValue: any) => {
    this.setState({ loader: loaderValue });
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        {/* this.props.data.clickmenuSync */}
        {(this.state.loader) ?
          <button
            type="button"
            title="Re-Push"
            className="btn btn-sm btn-outline-primary"
            disabled={this.props.data.clickmenuSync}
          >
            <Loader type="TailSpin" color="red" height={30} width={30} />
          </button> :
          <button
            type="button"
            title="Re-Push"
            className="btn btn-sm btn-outline-primary"
            disabled={(row.check_number === '') ? false : true}
            onClick={this.handleSaveSync}
          >
            <i className="fa fa-undo"></i>
          </button>
        }
      </div >
    );
  }
}
class FPOrderManualSyncButton extends Component<{ row: any }, { [x: number]: any, loader: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      loader: false
    }
  }
  handleSaveSync = () => {
    const { row } = this.props
    this.props.data.manualSyncFPFailedOrder(row.id, this.updateLoaderState);
  }
  updateLoaderState = (loaderValue: any) => {
    this.setState({ loader: loaderValue });
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        {(this.state.loader) ?
          <button
            type="button"
            title="Re-Push"
            className="btn btn-sm btn-outline-primary"
            disabled={this.props.data.clickmenuSync}
          >
            <Loader type="TailSpin" color="red" height={30} width={30} />
          </button> :
          <button
            type="button"
            title="Re-Push"
            className="btn btn-sm btn-outline-primary"
            disabled={(row.is_success === 0) ? false : true}
            onClick={this.handleSaveSync}
          >
            <i className="fa fa-undo"></i>
          </button>
        }
      </div >
    );
  }
}
const selectOrderStatusEditor = (cell: any, row: any, props: any) => {
  return (
    <OrderEditor row={row} data={props} />
  )
}
const xmlformatter = (cell: any, row: any, props: any) => {
  return (
    <XMLEditor row={row} data={props} />
  )
}
const pulseReqformatter = (cell: any, row: any, props: any) => {
  return (
    <ReqXMLEditor row={row} data={props} />
  )
}
const manualSyncformatter = (cell: any, row: any, props: any) => {
  return (
    <ManualSyncButton row={row} data={props} />
  )
}
const fpManualSyncformatter = (cell: any, row: any, props: any) => {
  return (
    <FPOrderManualSyncButton row={row} data={props} />
  )
}
class StoresEditor extends React.Component<{ data: any, row: any, closeTimer: any }, { store_id: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      store_id: ""
    }
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ store_id: row.store_id })

  }
  handleSave = () => {
    const { row } = this.props

   selectedStoreIdForOrderTransfer = this.state.store_id
    this.openModal.click();
    // this.props.closeTimer()
    this.props.data.updateSelectedRow(row)
  }
  onSearch = (e: any) => {
    this.setState({ store_id: e.value, })
  }

  render() {
    let activeStore: any = []
    const { data, row } = this.props;
    const { store_id } = this.state;
    let tokenData: any = jwt.decode(localStorage.token)
    let roleId = tokenData.role_id;
    data.stores.map((obj: any) => {
      if (obj.is_active == 1) {
        activeStore.push(obj)
      }
    })
    return (
      <div>
        <div>
          {row.is_order_transfer == 0
            ?
            <Select
              isSearchable={true}

              onChange={this.onSearch}

              placeholder={row.store_name}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize: "12px" }) }}
              options={activeStore}
              isOptionDisabled={(option: any) => option.store_id === row.store_id && true}
              isLoading={data.stores.length == 0 ? true : false}
            />
            : <p title={row.store_name}>{row.store_name.length < 20 ? row.store_name : `${row.store_name.slice(0, 20)}...`}</p>
          }
          {(row.is_order_transfer == 0 && store_id !== row.store_id) && <> <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span></>}
        </div>
        <div style={{ display: "none" }}>
          <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewordertransfer`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
        </div>
      </div>
    );
  }
}
const orderTransferEditor = (cell: any, row: any, props: any, closeTimer: any) => {
  return (
    <StoresEditor row={row} data={props} closeTimer={closeTimer} />
  )
}


function priceFormatter(cell: any, row: any) {
  if (row.aggregator_orderId) {
    return `${priceunit}${cell}`;
  } else {
    return `${priceunit}${Math.round(cell)}`;
  }
}

function isOrderTransferFormatter(cell: any, row: any) {
  if (row.is_order_transfer == 0) {
    return <p title="No">No</p>;
  } else {
    return <p title="Yes">Yes</p>;
  }
}
// function complaintFormatter(cell: any, row: any, props: any) {
//   return (
//     <Complaint row={row} data={props} />
//   );
// }
function complaintFormatter(cell: any, row: any, props: any) {
  return (
    <button
      title="Feedback"
      className="btn btn-outline-primary"
      data-toggle="modal"
      data-target={`#RegFeedback`}
      onClick={() => {
        props.updateSelectedRow(row)
      }}
    >
      <i className="fa fa-comment"></i>
    </button>
  );
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
function InvoiceComp(props: any) {
  return (
    <div id={`viewinvoice`} data-backdrop='static' data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
      <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
            <button type="button"
              data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
          </div>
          <div className="modal-body">
            <Invoice data={props.data} orderId={selectedOrderId} />
          </div>
        </div>
      </div>
    </div>
  )
}
function skeltonFormatter() {
  return (
    <Skelton></Skelton>
  );
}
// here
class OrderTransferComp extends React.Component<{ data: any, selectedRow: any, updateLoader: any, startTimer: any,}, { [x: number]: any, order_transfer_caller: any, transferReason: any, loader: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      order_transfer_caller: "",
      transferReason: "",
      loader: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.orderTransfer = this.orderTransfer.bind(this);
  }
  updateLoaderState = (loaderValue: any) => {
    this.setState({ loader: loaderValue });
  }
  isOrderTransferReady = () => {
    const { order_transfer_caller, transferReason } = this.state;
    return (order_transfer_caller !== "" && transferReason !== "");
  }
  orderTransfer(order_id: any) {
    let { selectedRow } = this.props;
    console.log();
    
    const data: any = {
      store_id: selectedStoreIdForOrderTransfer,
      order_transfer_caller: this.state.order_transfer_caller,
      order_transfer_reason: this.state.transferReason,
      is_order_transfer: 1
    }
    this.setState({ loader: true })
    this.props.data.orderTransfer(order_id, data, this.closeModal, this.updateLoaderState, this.props.updateLoader, this.props.startTimer);
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { data, selectedRow } = this.props;
    return (
      <div>
        <div id={`viewordertransfer`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
          <div role="document" className="modal-dialog" style={{ minWidth: "600px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">Order Transfer</h4>
                <button type="button" data-dismiss="modal" aria-label="Close" className="close" onClick={() => this.props.startTimer()}><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <h6>Are you sure,you want to transfer this order from one store to another?</h6>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <select
                        name="transferReason"
                        onChange={this.handleInputChange}
                        className="form-control mt-2">
                        <option value="">Select Reason</option>
                        {data.transferReasons &&
                          data.transferReasons.map((reason: any, index: any) => (
                            <option key={index} value={reason.id}>{reason.reason}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-group">
                      <input
                        id="order_transfer_caller"
                        style={{ fontSize: 15 }}
                        type="text"
                        name="order_transfer_caller"
                        placeholder="Caller Name"
                        className="input-material"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                </div>
                <div className="form-group d-flex justify-content-end mt-4">
                  {this.state.loader ?
                    <button className='btn btn-primary btn-danger'>
                      <Loader type="TailSpin" color="red" height={30} width={30} />
                    </button> :
                    <button className='btn btn-primary btn-danger' disabled={!this.isOrderTransferReady()} onClick={() => this.orderTransfer(selectedRow.order_id)}>Save</button>
                  }
                </div>
              </div>
              <div style={{ display: "none" }}>
                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function ComplaintComp(props: any) {
  return (
    <div
      id={`RegFeedback`}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      className="modal fade text-left"
    >
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">
              Customer Feedback
            </h4>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Complaint />
          </div>
        </div>
      </div>
    </div>
  );
}
class order extends Component<OrderProps, OrderState> {
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      days: 0,
      orderId: '',
      startDate: "",
      endDate: "",
      validTimeFlag: "",
      store_id: "",
      ordersArray: null,
      stores: [],
      loader: true,
      startTimer: 0,
      refresh: true,
      searchType: "orderId",
      foodPandaId: "",
      listType: 'current',
      storeLabel: "All",
      debounceTimeout:null,
      storeValue: "all",
      tabNum: '1',
    }
    this.handleValidTime = this.handleValidTime.bind(this);
    this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
  }

  componentDidMount() {
    this.props.storesList();
    this.props.complaintReasonsList();
    this.props.orderStatusList();
    this.props.orderTransferReasons();
  }
  rowClassNameFormat(row: any, rowIdx: any) {
    // row is whole row object
    // rowIdx is index of row
    if (row.check_number) {
      return 'td-column-function-aggregator-example';
  } else {
      if (row.future_status == 1 && row.order_status_code == 1) {
          return 'td-column-function-odd-example tr-pending-orders'
      } else if (row.future_status == 1) {
          return 'td-column-function-odd-example'
      } else if (row.order_status_code == 1 && row.is_order_transfer == 1) {
          return 'td-column-function-even-example tr-transfered-orders'
      } else if (row.order_status_code == 1) {
          return 'td-column-function-even-example tr-pending-orders'
      } else if (row.is_order_transfer == 1) {
          return 'td-column-function-even-example tr-transfered-orders'
      } else if (row.isNew == true) {
          return 'td-column-function-even-example'
      } else if (row.order_status_code == 1) {
          return 'tr-pending-orders'
      } else if (row.is_order_transfer == 1) {
          return 'tr-transfered-orders'
      } else {
          return ''
      }
  }
  }
  handleValidTime(event: { target: { name: any; value: any; }; }) {
    let futureDatetime = new Date(event.target.value)
    let validMinDateTime = new Date();
    if (futureDatetime <= validMinDateTime) {
      this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
    } else {
      this.setState({ validTimeFlag: false })
      toast.error("Invalid date and time selected");
    }
  }
  isOrderDateReady = () => {
    let { startDate, endDate, validTimeFlag } = this.state;
    return (startDate !== "" && endDate !== "" && validTimeFlag == true);
  }
  handleSubmit() {
    let { startDate, endDate } = this.state;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      pageNumber: 1,
      pageSize: DEFUALT_PAGE_SIZE
    }
    this.setState({ startTimer: this.state.startTimer + 1, listType: "dataTime" });
    this.props.aggregatorOrdersList(data, true, this.updateLoader);
  }
  handleInput = (event: { target: { name: any; value: any; }; }) => {
    this.setState({ [event.target.name]: event.target.value })
  };

  // handleSearchOrder = (event: any) => {
  //   event.preventDefault();
  //   let { orderId } = this.state;
  //   this.props.searchOrder(orderId)
  // }
  handleSearchOrder = (event: any) => { //here
    event.preventDefault();
    let { orderId, searchType, foodPandaId } = this.state;
    if (searchType == "orderId") this.props.searchOrder(orderId)
    else if (searchType == "foodPandaId") this.props.searchOrderByfoodPandaId(foodPandaId)
    this.setState({ ordersArray: null })
  }

  refreshOrders = () => {
    let obj = {
      days: 0
    }
    this.props.aggregatorOrdersList(obj);
  }

  handleStoresInputChange = (e: any, index: any) => {
    let obj: any = {
      days: this.state.days, pageNumber: 1,
      pageSize: DEFUALT_PAGE_SIZE
    };
    let { startDate, endDate } = this.state;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      pageNumber: 1,
      pageSize: DEFUALT_PAGE_SIZE
    }
    if (this.state.listType == "current") {

      this.props.aggregatorOrdersList(obj, null)
    } else {
      this.props.aggregatorOrdersList(data, null,)
    }
    if (e && e.value !== 'all') {
      console.log(e,"sadsadsa");
      let orders: any = this.props.orders && this.props.orders.filter(order => e.value == order.store_id);
      this.setState({ ordersArray: orders,storeLabel:e.store_name,storeValue:e.value })
      console.log("e.value.store_id", orders)
      // ==store.store_id

    } else {
      this.setState({ ordersArray: null,storeLabel:"All",storeValue:"all" })
    }
    //console.log("E ", e.value);
  };

  updateLoader = (value: any) => {
    this.setState({ loader: value })
  }
  startTimer = () => {
    this.setState({ refresh: true })
  }
  handlePagination = () => {
    let { startDate, endDate, days } = this.state;

    let obj: any = {
      pageNumber: parseInt(this.props.pageNumber),
      pageSize: parseInt(this.props.pageSize)
    }
    if (startDate && endDate) {
      let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
      let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
      obj.start = startDateUtc
      obj.end = endDateUtc
    }
    else {
      obj.days = days;
    }
    this.props.aggregatorOrdersList(obj, null, this.updateLoader)
  }
  changeTab = (value: any) => {
    this.setState({ tabNum: value })
    let { startDate, endDate, days } = this.state;

    let obj: any = {
      pageNumber: parseInt(this.props.pageNumber),
      pageSize: parseInt(this.props.pageSize)
    }
    if (startDate && endDate) {
      let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
      let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
      obj.start = startDateUtc
      obj.end = endDateUtc
    }
    else {
      obj.days = days;
    }
    value == "1" ? this.props.aggregatorOrdersList(obj, null, this.updateLoader) : this.props.aggregatorFailedOrdersList(this.updateLoader)
  }
  clearDateTime = () => {
    this.setState({
      startDate: "",
      endDate: "",
    })
  }
  clearData = () => {
    this.setState({
      ordersArray: null,
      storeLabel:"All",
      storeValue:"all",
      orderId:"",
      foodPandaId:""
    })
  }

  handleTimer = () => {
    if (this.state.refresh) {
      this.setState({ refresh: false })
    } else {
      this.setState({ refresh: true })
    }
    this.closeModal.click();
  }
  handleSearchType = (event: any) => {
    this.setState({ searchType: event.target.value })
  }
  handleSearchQuery = (e: any) => {
    const { startDate, endDate } = this.state;
    const startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm');
    const endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm');
    let data: any = {
      start: startDateUtc,
      end: endDateUtc
    };

    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    clearTimeout(this.state.debounceTimeout);

    const newDebounceTimeout = setTimeout(() => {
      if (searchQuery && searchQuery.length >= 3) {
        if (this.state.listType == "dataTime") {
          console.log(this.state.days, "asfdsaffsaafa");
          this.props.searchOrdersByQuery(searchQuery, data);
        } else {
          const obj = {
            days: 0
          };
          this.props.searchOrdersByQuery(searchQuery, obj);
        }
      } else if (!searchQuery) {
        let { startDate, endDate, days } = this.state;

        let obj:any = {
          pageNumber: parseInt(this.props.pageNumber),
          pageSize: parseInt(this.props.pageSize)
        }
        if (startDate && endDate) {
          let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
          let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
          obj.start = startDateUtc
          obj.end = endDateUtc
        }
        else {
          obj.days = days;
        }
      this.props.filterOrdersList(obj, this.updateLoader, null, null)
      }
    }, 400);
    this.setState({ debounceTimeout: newDebounceTimeout });
  };
  FilterbyStatus(status_code: any) {
    let order_status_codes = []
    let obj: any = { days: this.state.days };
    let { startDate, endDate } = this.state;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    let data: any = {
      start: startDateUtc,
      end: endDateUtc
    }
    if (status_code === 5) {
      order_status_codes.push(5);
      order_status_codes.push(6);
      obj.order_status = order_status_codes;
      data.order_status = order_status_codes;
    } else if (status_code == "failedIntegration") {
      obj.failedOrders = true;
      data.failedOrders = true;
    } else {
      order_status_codes.push(status_code)
      obj.order_status = order_status_codes;
      data.order_status = order_status_codes;
    }
    if (this.state.listType == "current") {

      this.props.filterOrdersList(obj, this.updateLoader, null, null)
    } else {
      this.props.filterOrdersList(data, this.updateLoader, null, null)
    }

  }
  render() {
    const dataa = [
      { id: 1, name: 'John Smith', age: 32, email: 'john.smith@example.com' },
      { id: 2, name: 'Jane Doe', age: 27, email: 'jane.doe@example.com' },
      { id: 3, name: 'Bob Johnson', age: 41, email: 'bob.johnson@example.com' },
      { id: 4, name: 'Alice Williams', age: 25, email: 'alice.williams@example.com' },
      { id: 5, name: 'Jack Brown', age: 38, email: 'jack.brown@example.com' },
      { id: 6, name: 'Linda Davis', age: 29, email: 'linda.davis@example.com' },
      { id: 7, name: 'Mike Garcia', age: 45, email: 'mike.garcia@example.com' },
      { id: 8, name: 'Samantha Lee', age: 31, email: 'samantha.lee@example.com' },
      { id: 9, name: 'David Nguyen', age: 36, email: 'david.nguyen@example.com' },
      { id: 10, name: 'Emily Taylor', age: 24, email: 'emily.taylor@example.com' }
    ];
    let { orders, agg_failed_orders } = this.props;
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Orders Not Found"
    };
    let storesArray = this.props.stores;
    let newObj = {
      value: 'all',
      label: "All"
    }
    if (storesArray && storesArray.length > 0) {
      if (storesArray[0].value !== 'all') {
        storesArray.unshift(newObj)
      }
    }
    let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  {/* <h4 className="mt-2">FoodPanda Orders</h4> */}
                  <div className="d-flex">
                    <h4 className="mt-2 mr-4">FoodPanda Orders<button title={this.state.refresh == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.state.refresh == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button></h4>
                    {this.state.refresh && <ReactTimer timerKey={this.state.startTimer} selectedTab={this.state.tabNum} days={this.state.days} updateLoader={this.updateLoader} clearDateTime={this.clearDateTime} clearData={this.clearData} />}
                  </div>
                  <div className="row">
                    <div className="col-2">
                      {/* <button className="btn btn-danger mr-3" style={{ padding: '0 15px', height: "40px" }} onClick={this.refreshOrders} ><i className="fa fa-refresh"></i></button> */}
                    </div>
                    <div className="col-5">
                      <div className="form-group">
                        <input
                          id="start"
                          type="datetime-local"
                          name="startDate"
                          max={maxDate}
                          value={this.state.startDate}
                          className="input-material"
                          onChange={this.handleValidTime}
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="form-group mb-1">
                        <input
                          id="end"
                          type="datetime-local"
                          name="endDate"
                          max={maxDate}
                          value={this.state.endDate}
                          className="input-material"
                          onChange={this.handleValidTime}
                        />
                      </div>
                      <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <section className="tables pt-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <Tabs>
                          <div className="tab-btns-setting">
                            <div>
                              <TabLink
                                activeClassName="btn btn-danger"
                                className="btn"
                                to="tab1"
                                onClick={() => { this.changeTab("1") }}
                              >
                                Orders
                              </TabLink>
                              <TabLink
                                activeClassName="btn btn-danger"
                                className="btn"
                                to="tab2"
                                onClick={() => { this.changeTab("2") }}
                              >
                                Failed Orders
                              </TabLink>
                            </div>
                          </div>

                          <TabContent className="mt-4" for="tab1">
                            <>
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-12 zclass pt-2">
                                  <Select
                                    isSearchable={true}
                                    defaultValue={{ label: this.state.storeLabel, value: this.state.storeValue }}
                                    value={{ label: this.state.storeLabel, value: this.state.storeValue }}
                                    options={storesArray}
                                    isLoading={storesArray.length == 0 ? true : false}
                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                  />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                  <div className='row'>
                                    <div className="col-6">
                                      <div className="form-group">
                                        <select name="day" className="form-control mt-2" required data-msg="Select Day" onChange={this.handleSearchType}>
                                          <option key={0} value={"orderId"}>Search by order Id</option>
                                          <option key={1} value={"foodPandaId"}>Search by FoodPanda order Id</option>
                                        </select>
                                      </div>
                                    </div>
                                    {this.state.searchType == "orderId" &&
                                      <div className="form-group col-6">
                                        <input id="orderId" type="number" onWheel={(e: any) => e.target.blur()} value={this.state.orderId} defaultValue={this.state.orderId} name="orderId" required placeholder="Search by order id" className="input-material" onChange={this.handleInput} />
                                      </div>
                                    }
                                    {this.state.searchType == "foodPandaId" &&
                                      <div className="form-group col-6">
                                        <input id="foodPandaId" type="text" onWheel={(e: any) => e.target.blur()} value={this.state.foodPandaId} defaultValue={this.state.foodPandaId} name="foodPandaId" required placeholder="Search by FoodPanda Order id" className="input-material" onChange={this.handleInput} />
                                      </div>
                                    }
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-2 col-12 float-right mt-1">
                                  <div className="form-group">
                                    <button onClick={this.handleSearchOrder} disabled={this.state.orderId == "" && this.state.foodPandaId == ""} className="btn btn-danger btn-block">Search Order</button>
                                  </div>
                                </div>
                                <div className="col-6 mt-3 ml-3">
                                  <PaginationComp handlePagination={this.handlePagination} />
                                </div>
                                <input type='text' value={this.state.searchQuery} onChange={this.handleSearchQuery} style={{ marginLeft: "12vh" }} placeholder='Search' className="input-dominos-search mt-3 mb-3 col-3" />
                                <div className="col-lg-2 col-md-2 float-right mt-3" style={{ paddingRight: "unset", paddingLeft: "25px" }}>
                                  <div className="form-group">
                                    <button onClick={() => { this.FilterbyStatus('failedIntegration') }} className="btn btn-danger btn-block">Sync Failed {this.props.failedIntegOrderCount}</button>
                                  </div>
                                </div>
                              </div>

                              {this.state.loader ?
                                <BootstrapTable
                                  version="4"
                                  data={dataa}
                                  search={false}
                                  options={options}
                                >

                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField='1'
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Order Id"
                                    dataSort={true}
                                    isKey
                                  >
                                    Order Id
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}

                                  >
                                    Details
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} >Store</TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Order Type"
                                    columnTitle
                                  >
                                    Order Type
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Order Channel"
                                    columnTitle
                                  >
                                    Order Channel
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Status' columnTitle>Status</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Agent Name"
                                    columnTitle
                                  >
                                    Agent Name
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Customer Name"
                                    columnTitle
                                  >
                                    Name
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Phone"
                                    columnTitle
                                  >
                                    Phone
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Address"
                                    columnTitle
                                  >
                                    Address
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Price"
                                  >
                                    Order Price
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Discount"
                                  >
                                    Discount
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Payment Method"
                                    columnTitle
                                  >
                                    Payment Method
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Cancel Reason"
                                    columnTitle
                                  >
                                    Cancel Reason
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Caller For Order Transfer' columnTitle>Caller For Order Transfer</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Order Transfer' columnTitle>Order Transfer</TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Order Transfer Reason"
                                    columnTitle
                                  >
                                    Order Transfer Reason
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                    csvHeader="Order Recieved"

                                    columnTitle
                                  >
                                    Order Recieved
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter} >Re Push</TableHeaderColumn>
                                  {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={pulseReqformatter} formatExtraData={this.props} export={false}>Pulse Request</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={xmlformatter} formatExtraData={this.props} export={false}>Pulse Response</TableHeaderColumn> */}
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataFormat={skeltonFormatter}
                                  >
                                    Action
                                  </TableHeaderColumn>


                                </BootstrapTable>
                                :
                                <BootstrapTable
                                  version="4"
                                  data={this.state.ordersArray == null ? orders : this.state.ordersArray.length === 0 ? [] : this.state.ordersArray}
                                  // data={(this.state.ordersArray == null||this.state.ordersArray.length === 0) ? orders : this.state.ordersArray}
                                  search={false}
                                  //pagination={orders.length > 10 && true}
                                  options={options}
                                  trClassName={this.rowClassNameFormat}
                                  hover
                                >

                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="order_id"
                                    csvHeader="Order Id"
                                    dataSort={true}
                                    isKey
                                  >
                                    Order Id
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="action"
                                    dataFormat={actionFormatter}
                                    formatExtraData={this.props}
                                    export={false}
                                  >
                                    Details
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_id' dataFormat={(cell, row, props) => orderTransferEditor(cell, row, props, this.closeTimer)} formatExtraData={this.props} >Store</TableHeaderColumn>
                           
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="delivery_status"
                                    csvHeader="Order Type"
                                    columnTitle
                                  >
                                    Order Type
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="order_channel"
                                    csvHeader="Order Channel"
                                    columnTitle
                                  >
                                    Order Channel
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_time_json' dataFormat={calculateDeliveryTime} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                                  {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_status_description' dataFormat={selectOrderStatusEditor} formatExtraData={this.props} export={false}>Approval</TableHeaderColumn> */}
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_status_description' csvHeader='Status' columnTitle>Status</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='check_number' csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='failure_reason' csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="agent_name"
                                    csvHeader="Agent Name"
                                    columnTitle
                                  >
                                    Agent Name
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="login_name"
                                    csvHeader="Customer Name"
                                    columnTitle
                                  >
                                    Name
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="phone_number"
                                    csvHeader="Phone"
                                    columnTitle
                                  >
                                    Phone
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="delivery_address"
                                    csvHeader="Address"
                                    columnTitle
                                  >
                                    Address
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="order_grossprice"
                                    dataFormat={priceFormatter}
                                    csvHeader="Price"
                                  >
                                    Order Price
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="discount"
                                    dataFormat={priceFormatter}
                                    csvHeader="Discount"
                                  >
                                    Discount
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="payment_method"
                                    csvHeader="Payment Method"
                                    columnTitle
                                  >
                                    Payment Method
                                  </TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="reason"
                                    csvHeader="Cancel Reason"
                                    columnTitle
                                  >
                                    Cancel Reason
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField="date_created"
                                    csvHeader="Order Recieved"
                                    dataFormat={dateFormatter}
                                    columnTitle
                                  >
                                    Order Recieved
                                  </TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={manualSyncformatter} formatExtraData={this.props} export={false}>Re Push</TableHeaderColumn>
                                  {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={pulseReqformatter} formatExtraData={this.props} export={false}>Pulse Request</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={xmlformatter} formatExtraData={this.props} export={false}>Pulse Response</TableHeaderColumn> */}
                                  <TableHeaderColumn
                                    thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                    dataField='action'
                                    dataFormat={complaintFormatter}
                                    formatExtraData={this.props}
                                    export={false}>
                                    Action
                                  </TableHeaderColumn>
                                </BootstrapTable>
                              }
                              <div className="bottomPagination">
                                <PaginationComp handlePagination={this.handlePagination} />
                              </div>
                            </>
                          </TabContent>

                          <TabContent className="mt-4" for="tab2">
                            <>
                              {this.state.loader ?
                                <BootstrapTable
                                  version="4"
                                  data={dataa}
                                  search={false}
                                  options={options}
                                >
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='1' dataFormat={skeltonFormatter} csvHeader="Id" dataSort={true} isKey>Id</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter}>Aggregator OrderId</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} >Store</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} width='200' tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataFormat={skeltonFormatter}>Exception</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter}>Order Json</TableHeaderColumn>
                                  {/* <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter} >Re Push</TableHeaderColumn> */}
                                </BootstrapTable>
                                :
                                <BootstrapTable
                                  version="4"
                                  data={agg_failed_orders}
                                  search={false}
                                  options={options}
                                  trClassName={this.rowClassNameFormat}
                                  hover
                                >
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField="id" csvHeader="Id" dataSort={true} isKey>Id</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_name' csvHeader="Store" columnTitle>Store</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='exception_description' dataFormat={exceptionFormatter} csvHeader='Exception' columnTitle>Exception</TableHeaderColumn>
                                  <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='action' dataFormat={fpJsonformatter} formatExtraData={this.props} export={false}>Order Json</TableHeaderColumn>
                                  {/* <TableHeaderColumn thStyle={{ fontSize: 10, whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={fpManualSyncformatter} formatExtraData={this.props} export={false}>Re Push</TableHeaderColumn> */}
                                </BootstrapTable>
                              }
                            </>
                          </TabContent>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
        <div>
        <InvoiceComp data={this.props} />
          <ComplaintComp data={this.props} />
          <OrderTransferComp data={this.props}  selectedRow={this.props.selectedRow} updateLoader={this.updateLoader} startTimer={this.startTimer} />
          <div id="enableTimer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">Auto Refresh</h4>
                  <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                </div>
                <div className="modal-body">
                  <p>Are you sure,you want to {this.state.refresh == false ? "enable" : "disable"} auto refresh?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                  <button onClick={this.handleTimer} className="btn btn-primary">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function calculateDeliveryTime(cell: any, row: any) {
  let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
  let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.order_placed + DeliveryTime.prep + DeliveryTime.bake + DeliveryTime.quality_check : 0
  return (
    <div>
      <span {...((row.order_status_code == 6 && row.delivery_time_json) && { title: `${ActualDeliveryTime} min` })}> {(row.order_status_code == 6 && row.delivery_time_json) && `${ActualDeliveryTime} min`} </span>
    </div>
  )
}
function dateFormatter(cell: any) {
  var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
  DateCreated = DateCreated[1] + " , " + DateCreated[0];
  return (
    <div>
      <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
    </div>
  )
}
class FPOrderJsonEditor extends Component<{ row: any }, { [x: number]: any, editJson: any, fp_json: any,is_update_json:any }> {
  closeModal: any;
  constructor(readonly props: any) {
    super(props);
    this.state = {
      editJson: false,
      fp_json: JSON.parse(this.props.row.order_json),
      is_update_json:false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleSaveSync = () => {
    const { row } = this.props
    const data = {
      fp_json: JSON.stringify(this.state.fp_json),
      is_update_json:this.state.is_update_json
    }
    this.props.data.manualSyncFPFailedOrder(row.id, data, this.closeModal);
    this.setState({ editJson: false })
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.setState({is_update_json:true})
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title="View FP Request"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#ViewFPReq${row.id}`}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`ViewFPReq${row.id}`}
          data-backdrop='static'
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  FP Request Body
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                  onClick={() => this.setState({ editJson: false })}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className='d-flex justify-content-between mb-2'>
                  {this.state.fp_json && this.state.fp_json !== "" &&
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      onClick={() => this.setState({ editJson: true })}
                      disabled={row.is_success === 0 ? false : true}
                    >
                      <i className="fa fa-edit"></i> Update Order Json
                    </button>
                  }
                  <button
                    type="button"
                    className="btn btn-sm btn-info"
                    disabled={(this.state.editJson && row.is_success === 0) ? false : true}
                    onClick={this.handleSaveSync}
                  >
                    <i className="fa fa-undo"></i> Re-Push
                  </button>
                </div>
                <div className="form-row" >
                  <div className="form-group col-md-12">
                    <textarea name="fp_json" id="round2" disabled={this.state.editJson ? false : true} style={{ width: '100%', height: '300px' }} onChange={this.handleInputChange}>
                      {JSON.stringify(this.state.fp_json, undefined, 4)}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                  onClick={() => this.setState({ editJson: false })}
                >
                  Close
                </button>
              </div>
              <div style={{ display: "none" }}>
                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
const fpJsonformatter = (cell: any, row: any, props: any) => {
  return (
    <FPOrderJsonEditor row={row} data={props} />
  )
}
class FailureExceptionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  render() {
    const { row } = this.props;

    return (
      <div>
        {row.exception_description && row.exception_description.length > 30 ?
          <button
            title="View Exception"
            className="btn btn-outline-primary"
            data-toggle="modal"
            data-target={`#ViewFeedbackMessage${row.id}`}
          >
            View Exception
          </button>
          :
          <>
            {row && row.exception_description}
          </>
        }

        {/* <!-- Modal--> */}
        <div
          id={`ViewFeedbackMessage${row.id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Failure Exception
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row" >
                  <div className="form-group col-md-12">
                    <textarea name="feedbackMessage" id="round2" disabled style={{ width: '100%', height: '100px' }}>
                      {row.exception_description}
                    </textarea>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function exceptionFormatter(cell: any, row: any, props: any) {
  return <FailureExceptionFormatter row={row} />;
}
const mapStateToProps = (state: any) => {
  return {
    orders: state.order.agg_orders,
    agg_failed_orders: state.order.agg_failed_orders,
    stores: state.customer.storesList,
    Items: state.order.orderItems,
    orderDetail: state.order.order,
    statusList: state.order.orderStatus,
    transferReasons: state.order.orderTransferReasons,
    clickmenuSync: state.order.clickmenuSync,
    pageSize: state.order.pageSize,
    pageNumber: state.order.pageNumber,
    failedIntegOrderCount: state.order.failedIntegOrderCount,
    orderXML: state.order.orderXML,
    selectedRow: state.order.selectedRow,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    aggregatorOrdersList: (obj: any, datetime: any, updateLoader?: any) => {
      dispatch(aggregatorOrdersList(obj, datetime, updateLoader));
    },
    aggregatorFailedOrdersList: (updateLoader?: any) => {
      dispatch(aggregatorFailedOrdersList(updateLoader));
    },
    newmanualSyncOrder: function (id: any, data: any, modal: any, updateLoaderState?: any) {
      dispatch(newmanualSyncOrder(id, data, modal, updateLoaderState))
    },
    manualSyncFPFailedOrder: function (id: any, data: any, modal: any, updateLoaderState?: any) {
      dispatch(manualSyncFPFailedOrder(id, data, modal, updateLoaderState))
    },
    storesList: function () {
      dispatch(getStores());
    },
    showorderItems: function (id: any, orders: any) {
      dispatch(showorderItems(id, orders))
    },
    complaintReasonsList: function () {
      dispatch(complaintReasonsList());
    },
    orderStatusList: function () {
      dispatch(orderStatusList())
    },
    orderTransferReasons: () => {
      dispatch(orderTransferReasons())
    },
    updateOrder: function (id: any, data: any, history: any) {
      dispatch(updateOrder(id, data, history))
    },
    orderTransfer: function (id: any, data: any, history: any) {
      dispatch(aggOrderTransfer(id, data, history))
    },
    searchOrder: (orderId: any) => {
      dispatch(searchFPordersByOrderID(orderId))
    },
    searchOrderByfoodPandaId: (orderId: any) => {
      dispatch(searchOrderByfoodPandaId(orderId))
    },
    updateSelectedRow: (row: any) => {
      dispatch(updateSelectedRow(row))
    },
    filterOrdersList: (filteredArr: any, updateLoader?: any, closeTimer?: any, startTimer?: any) => {
      dispatch(filterFPOrdersList(filteredArr, updateLoader, closeTimer, startTimer))
    },
    searchOrdersByQuery: (query: any, obj: any) => {
      dispatch(searchOrdersByQueryFP(query, obj))
  },
  getOrderXML: function (id: any) {
    dispatch(getOrderXML(id))
},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(order);
