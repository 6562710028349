import Api from '../../components/Api';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ACTIVE_AGENTS_LIST, AGENTS_TARGET_LIST, GET_AGENT } from './settingsType';
toast.configure();

export const getAgent = (id: number) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        Api.get(`/call-portal/agent/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("successResponse",response.data)
                    dispatch({
                        type: GET_AGENT,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const saveAgentTarget = (data: any) => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/call-portal/save_agent_target', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch(agentsTargetList())
                    //dispatch(statesList())
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const agentsTargetList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        var store: any = jwt_decode(token);
        Api.get(`/call-portal/agent_targets`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let AgentsTargetarray: any = []
                    AgentsTargetarray = response.data.successResponse
                    console.log("AgentsTargetarray array",AgentsTargetarray)
                    dispatch({
                        type: AGENTS_TARGET_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const activeAgentsList = () => {
    return function (dispatch: any) {
        let token: any = localStorage.getItem('token');
        var store: any = jwt_decode(token);
        Api.get(`/call-portal/agents/`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let Agentsarray: any = []
                    let activeAgentsarray: any = []
                    Agentsarray = response.data.successResponse;
                    Agentsarray.map((agent:any)=>{
                        if(agent.status == 1){
                            activeAgentsarray.push(agent)
                        }
                    })
                    console.log("active agent",activeAgentsarray)
                    dispatch({
                        type: ACTIVE_AGENTS_LIST,
                        payload: activeAgentsarray
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


