import { AGENTS_LIST, GET_AGENT } from '../actions/agentType'
const initialState: any = {
    agents: [] || "",
    agentData: {},
    state_id:"",
    message: "",
    isUpdated: "",
    isSaved: ""
}

const agentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AGENTS_LIST: return {
            ...state,
            agents: action.payload
        }
        // case ADD_DRIVER: return {
        //     ...state,
        //     message: action.payload,
        //     isInserted: action.isInserted,
        // }
        // case EDIT_AGENT: return {
        //     ...state,
        //     message: action.payload,
        //     isUpdated: action.isUpdated
        // }
        case GET_AGENT: return {
            ...state,
            agentData: action.payload
        }
        default: return state;
    }
}
export default agentReducer;