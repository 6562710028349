import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCart, logoutUser } from '../redux'
import Clock from './clock';
import { saveCallrecord, StartClockTimer, stopTimer } from '../redux/actions/headerAction';

class Topbar extends Component<{ cart: any[], time: any,saveCallrecord:(data:any) => {}, stopTimer: ()=> {}, getCart: () => {}, logoutUser: () => {} }, {}> {
    constructor(readonly props: any) {
        super(props);
        this.captureAgentStats=this.captureAgentStats.bind(this)
    }
    componentDidMount() {
        this.props.getCart();
    }
    captureAgentStats () {
        let {time} = this.props;
        console.log(time)
        time = time/60;
        let rounded_time = Math.round(time*10)/10
        console.log(rounded_time)
        let callStatsObj = {
            call_duration: rounded_time,
            call_status: 3,
            customer_number: localStorage.getItem('phone'),
            total_sale: 0,
          }
          this.props.saveCallrecord(callStatsObj);
       this.props.stopTimer()
    }
    render() {
        let { time } = this.props;
        return (
            <header className="header">
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className="navbar-holder d-flex align-items-center justify-content-between">
                            {/* <!-- Navbar Header--> */}
                            <div className="navbar-header">
                                {/* <!-- Navbar Brand --> */}
                                <Link to="/dashboard" className="navbar-brand d-none d-sm-inline-block">
                                    <div className="brand-text d-none d-lg-inline-block"><span>Dominos</span><strong>CRM</strong></div>
                                    <div className="brand-text d-none d-sm-inline-block d-lg-none"><strong>DominosCRM</strong></div></Link>
                                {/* <!-- Toggle Button--> */}
                                <Link id="toggle-btn" to="#" className="menu-btn active"><span></span><span></span><span></span></Link>
                            </div>
                            {/* <!-- Navbar Menu --> */}
                            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                                {/* <li className="nav-item" title="Cart"><Clock /></li> */}
                                {/* { this.props.showCount && */}
                                <li onClick={this.captureAgentStats} style={{cursor: 'pointer'}} className="nav-item">                        
                                <img src={process.env.PUBLIC_URL + "/assets/img/phone-hang-up.png"} alt="..." className="img-fluid rounded-circle" style={{ marginTop: 5, width:'25px'}} />
                                </li> 
                                <li style={{width:'100px'}} className="nav-item"><Clock time={time} /> </li>
                                <li className="nav-item" title="Cart"><Link to="/cart" className="nav-link"><i className="fa fa-shopping-cart"></i><span className="badge bg-red badge-corner">{this.props.cart.length > 0 && JSON.parse(this.props.cart).length}</span></Link></li>
                                <li className="nav-item" title="Profile"><Link to="/profile" className="nav-link"><i className="fa fa-user-circle"></i></Link></li>
                                {/* <!-- Logout    --> */}
                                <li onClick={() => this.props.logoutUser()} className="nav-item btn"><span className="d-none d-sm-inline mx-1">Logout</span><i className="fa fa-sign-out"></i></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        cart: state.cart.cartData,
        time: state.customer.clockCount,
        showCount: state.customer.showCount
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getCart: function () {
            dispatch(getCart());
        },
        stopTimer: function () {
            dispatch(stopTimer())
          },
          saveCallrecord: function (data:any) {
              dispatch(saveCallrecord(data))
          }

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);