import React, { Component } from 'react';
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { ComplaintProps, ComplaintState } from '../../interfaces/complaint';
import moment from 'moment';
import { submitComplaint } from '../../redux/actions/customerAction';
import { stopTimer } from '../../redux/actions/headerAction';
import { complaintReasonsList } from '../../redux/actions/customerComplaintAction';

class Complaint extends Component<ComplaintProps, ComplaintState> {
    constructor(props: any) {
        super(props);
        this.state = {
            feedbackType: "Complaint",
            feedbackMessage: "",
            netWork: false,
            reason_id: ""
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
    }

    handleInputChange(event: { target: { name: any; value: any } }) {
  
      if(event.target.value[0]==" "){
      }else{
        this.setState({
            [event.target.name]: event.target.value,
        });
      }
       
    }
    
    handleSubmitComplaint = (event: any) => {
        let { callduration, row } = this.props;
        const { feedbackMessage, feedbackType,reason_id,netWork } = this.state;
        this.setState({netWork: true});
        setTimeout(() => {
            this.setState({ netWork: false })
          }, 4000);
        const data: any = {
            name: row.login_name,
            phone_number: row.phone_number,
            receipt_number: row.order_id,
            message: feedbackMessage,
            type: feedbackType,
            store_id: row.store_id,
            order_date: row.date_created,
        };
        if(row.email_address !== null && row.email_address !== '' && row.email_address !== 'NULL') {
            data.email_address = row.email_address;
        }
        if(feedbackType === 'Complaint'){
            data.reason = reason_id
        }
        callduration = callduration / 60;
        let rounded_time = Math.round(callduration * 10) / 10
        let callStatsObj = {
            call_duration: rounded_time,
            call_status: 2,
            customer_number: localStorage.getItem('phone'),
            total_sale: 0,
            order_id: row.order_id,
        }
        this.props.submitComplaint(data, callStatsObj);
        this.props.stopTimer();
    };

    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }

    isUserReady = () => {
        const { feedbackMessage, feedbackType, reason_id } = this.state
        if (feedbackType === 'Complaint') {
            return (feedbackMessage !== '' && reason_id !== '');
        }
        else {
            return (feedbackMessage !== '')
        }
    }
    render() {
        const { row, reasonsList } = this.props;
        const dateOrder = row && moment(row.date_created)
            .local()
            .format("YYYY-MM-DD HH:mm")
        // const dateOrder = row.order_date

        return (
            <div className="form-row" >
                <div className="form-group col-md-6">
                    <h5 id="txt">  Name</h5>
                    <input name="fullname" defaultValue={row && row.login_name} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required disabled />
                </div>
                <div className="form-group col-md-6">
                    <h5 id="txt">Phone</h5>
                    <input name="phone" defaultValue={row && row.phone_number} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required disabled />
                </div>

                <div className="form-group col-md-6">
                    <h5 id="txt">Email</h5>
                    <input name="email" defaultValue={row && row.email_address} style={{ fontSize: "15px" }} type="email" className="form-control bg-white" id="round" required disabled />
                </div>
                <div className="form-group col-md-6">
                    <h5 id="txt">Store</h5>
                    <input type="text" name="dateOfOrder" defaultValue={row && row.store_name} required className="form-control bg-white" id="round" disabled />
                </div>

                <div className="form-group col-md-6">
                    <h5 id="txt">Order Date </h5>
                    <input type="text" name="dateOfOrder" defaultValue={dateOrder} required data-msg="Please Enter Order Date" className="form-control bg-white" id="round" disabled />
                </div>
                <div className="form-group col-md-6">
                    <h5 id="txt">  Receipt Number</h5>
                    <input name="receiptnumber" defaultValue={row && row.order_id} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" disabled />
                </div>
                <div className="form-group col-md-12">
                    <h5 id="txt">  Feedback Type </h5>
                    <select onChange={this.handleInputChange} className="custom-select mb-3 w-100" id="round1" name="feedbackType">
                        <option disabled value="">Select Feedback Type</option>
                        <option value="Complaint">Complaint</option>
                        <option value="Comments">Comments</option>
                        <option value="Suggestions">Suggestions</option>
                        <option value="Questions">Questions</option>
                    </select>
                </div>
                {this.state.feedbackType === 'Complaint' &&
                    <div className="form-group col-md-12">
                        <h5 id="txt">Reason <sup className="text-danger">*</sup></h5>
                        <select onChange={this.handleInputChange} className="custom-select mb-3 w-100" id="round1" name="reason_id">
                            <option value="">Select Reason</option>
                            {reasonsList &&
                                reasonsList.map((reason: any, index: any) => (
                                    <option key={index} value={reason.id}>{reason.reason}</option>
                                ))
                            }
                        </select>
                    </div>
                }
                <div className="form-group col-md-12">
                    <h5 id="txt">Feedback</h5>
                    <textarea onChange={this.handleInputChange} value={this.state.feedbackMessage} name="feedbackMessage" id="round2" style={{ width: '100%', height: '100px' }}>
                    </textarea>
                </div>

                <div className="form-group col-md-12">
                    {!this.state.netWork
                        ?
                        <button id="h" disabled={!this.isUserReady()} onClick={this.handleSubmitComplaint} type="button" className="btn btn-danger">Submit</button>
                        :
                        <button id="h" disabled={this.state.netWork} onClick={this.handleSubmitComplaint} type="button" className="btn btn-danger">Submit</button>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        callduration: state.customer.clockCount,
        reasonsList: state.customerComplaint.complaintReasonList,
        row: state.order.selectedRow,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        submitComplaint: function (data: any, callStatsObj: any) {
            dispatch(submitComplaint(data, callStatsObj))
        },
        stopTimer: function () {
            dispatch(stopTimer())
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Complaint);