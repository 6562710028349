import { DEFUALT_PAGE_SIZE } from "../../client-config";
import { AGENT_STATS, CANCEL_ORDER_REASONS, ORDERS_LIST, AGG_ORDERS_LIST, ORDER_ITEMS_LIST, ORDER_STATUS_LIST, ORDER_TRANSFER_REASONS, UPDATE_LOADER, UPDATE_TIMER, MANUAL_SYNC_ORDER, UPDATE_SELECTED_ROW, FILTERED_ORDERS, AGG_FAILED_ORDERS_LIST, XML_BY_ORDER_ID } from "../actions/orderType";

const initialState: any = {
    data: "" || [],
    orderItems: [] || "",
    orderStatus: [] || "",
    cancelReasons: [] || "",
    orderTransferReasons: [] || "",
    order: {},
    agent_stats: {},
    clickmenuSync:false,
    agg_orders:"" || [],
    agg_failed_orders:"" || [],
    pageNumber:1,
    filteredOrders: [] || "",
    pageSize:DEFUALT_PAGE_SIZE,
    total_orders_count:0,
    selectedRow: null,
    failedIntegOrderCount: 0,
    emptyFilterOrder: false,
}

const orderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ORDERS_LIST: return {
            ...state,
            data: action.payload
        }   
        case AGG_ORDERS_LIST: return {
            ...state,
            agg_orders: action.payload,
            failedIntegOrderCount: action.failedIntegOrderCount,
        }  
        case XML_BY_ORDER_ID: return {
          ...state,
          orderXML: action.payload
      } 
        case AGG_FAILED_ORDERS_LIST: return {
          ...state,
          agg_failed_orders: action.payload
        }
        case AGENT_STATS: return {
          ...state,
          agent_stats: action.payload
      }
      case ORDER_ITEMS_LIST: return {
        ...state,
        orderItems: action.payload,
        order: action.orderDetail
      } 
      case ORDER_STATUS_LIST: return {
        ...state,
        orderStatus: action.payload
      }
      case UPDATE_LOADER: return {
        ...state,
        isLoading: action.isLoading
    }
    case UPDATE_TIMER: return {
        ...state,
        isTimerUpdate: action.isTimerUpdate
    }
      case CANCEL_ORDER_REASONS: return {
        ...state,
        cancelReasons: action.payload
      }
      case ORDER_TRANSFER_REASONS: return {
        ...state,
        orderTransferReasons: action.payload
      }
      case MANUAL_SYNC_ORDER: return {
        ...state,
        clickmenuSync: action.payload
      }
      case "SET_PAGINATION": return {
        ...state,
        pageNumber: action.payload.pageNum,
        pageSize: action.payload.pSize
      }
      case "TOTAL_ORDERS_COUNT": return {
        ...state,
        total_orders_count: action.payload
        
      }
      case FILTERED_ORDERS: return {
        ...state,
        filteredOrders: action.payload,
        emptyFilterOrder: action.emptyFilterOrder,
        currentPage: action.currentPage,
        pageCount: action.pageCount,
        numOfRows: action.numOfRows
    }
      case UPDATE_SELECTED_ROW: return {
        ...state,
        selectedRow: action.payload
    }
      default:
        return state;
    }
  };
export default orderReducer;
  