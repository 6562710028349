import { COMPLAINT_REASONS, CUSTOMER_COMPLAINTS } from "../actions/customerComplaintType";

const initialState: any = {
    data: "" || [],
    complaintData: [] || "",
    complaintReasonList: [] || "",
}

const customerComplaintReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CUSTOMER_COMPLAINTS: return {
            ...state,
            complaintData: action.payload
        }
        case COMPLAINT_REASONS: return {
            ...state,
            complaintReasonList: action.payload
        }
      default:
        return state;
    }
  };
export default customerComplaintReducer;