import React, { Component } from "react";
import Sidebar from "../../components/sidebar";
import Topbar from "../../components/topbar";
import Footer from "../../components/footer/main";
import "./order.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import beautify from "xml-beautifier";
import _, { dropRight } from "lodash";
import { OrderProps, OrderState } from "../../interfaces/order";
import { connect } from "react-redux";
import Select from 'react-select';
import {
  getCart,
  getStores,
  getTaxValue,
  //   addCustomer,
  //   customerDetail,
  //   getCart,
  //   getStores,
  //   getTaxValue,
  //   handleAddressInput,
  //   handleCustomerInput,
  logoutUser,
  orderDetail,
  //   orderDetail,
  //   saveCart,
  //   validateEmail,
  ordersList,
  saveCart
} from "../../redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Complaint from '../customerDetail/complaint';
import Invoice from './FbrInvoice/fbrInvoice';

import { cancelOrdersReasons, getAgentStats, getOrderXML, newmanualSyncOrder, orderStatusList, orderTransfer, orderTransferReasons, searchOrder, searchOrdersByQuery, showorderItems, updateOrder, updateSelectedRow } from "../../redux/actions/orderAction";
import moment from "moment";
import jwt from 'jsonwebtoken'
import { complaintReasonsList } from "../../redux/actions/customerComplaintAction";
import { DEFUALT_PAGE_SIZE, priceunit } from "../../client-config";
import { toast } from "react-toastify";
import ReactTimer from "./timer"
import Skelton from "../skeltonLoader";
import PaginationComp from "../../components/pagination";
let selectedOrderId = 0;
let selectedStoreIdForOrderTransfer = 0;
class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showorderItems = (id: any) => {
    this.props.data.showorderItems(id, this.props.data.orders);
    selectedOrderId = id
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
      </div>
    )
  }
}
class XMLEditor extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title="View Pulse Response"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#ViewPulseResponse${row.order_id}`}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`ViewPulseResponse${row.order_id}`}
          data-backdrop='static'
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Pulse Response
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row" >
                  {row.failure_reason &&
                    <div className="form-group col-md-12">
                      <h5 id="txt">Pulse Integration Fail Reason</h5>
                      <textarea name="failure_reason" id="round2" disabled style={{ width: '100%', height: '50px' }}>
                        {row.failure_reason}
                      </textarea>
                    </div>
                  }
                  <div className="form-group col-md-12">
                    <h5 id="txt">Pulse Response XML</h5>
                    <textarea name="pos_response_xml" id="round2" disabled style={{ width: '100%', height: '300px' }}>
                      {row.pos_response_xml && beautify(row.pos_response_xml)}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class ReqXMLEditor extends Component<{ row: any }, { [x: number]: any, editXml: any, pos_request_xml: any, order_id: any, checkTimer: any }> {
  closeModal: any;
  constructor(readonly props: any) {
    super(props);
    this.state = {
      editXml: false,
      pos_request_xml: this.props.row.pos_xml,
      order_id: "",
      checkTimer: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ order_id: row.order_id })
    console.log("row", this.props)
  }
  handleTimer = () => {
    this.setState({ editXml: false })
    if (this.state.checkTimer == true) {
      this.props.data.luanchTimer(this.props.data.history);
    }
  }
  handleSaveSync = () => {
    const { row } = this.props
    const { order_id } = this.state;
    const data = {
      order_id: order_id ? order_id : "",
      pos_xml: this.state.pos_request_xml
    }
    this.props.data.newmanualSyncOrder(row.order_id, data, this.closeModal);
    this.setState({ editXml: false })
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        <button
          title="View Pulse Request"
          className="btn btn-outline-primary"
          data-toggle="modal"
          data-target={`#ViewPulseReq${row.order_id}`}
        // onClick={() => {
        //   this.props.data.stopTimer()
        //   if (this.props.data.timerStatus == true) {
        //     this.setState({ checkTimer: true })
        //   }
        // }}
        >
          <i className="fa fa-eye"></i>
        </button>
        {/* <!-- Modal--> */}
        <div
          id={`ViewPulseReq${row.order_id}`}
          data-backdrop='static'
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Pulse Request Body
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                // onClick={() => this.handleTimer()}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className='d-flex justify-content-between mb-2'>
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-info"
                    onClick={() => this.setState({ editXml: true })}
                    disabled={row.check_number === '' ? false : true}
                  >
                    <i className="fa fa-edit"></i> Update XML
                  </button> */}
                  {(this.props.data.clickmenuSync) ?
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      disabled={this.props.data.clickmenuSync}
                    >
                      <Loader type="TailSpin" color="white" height={30} width={30} />
                    </button> :
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      disabled={(this.state.editXml && row.check_number === '') ? false : true}
                      onClick={this.handleSaveSync}
                    >
                      <i className="fa fa-undo"></i> Re-Push
                    </button>
                  }
                </div>
                <div className="form-row" >
                  <div className="form-group col-md-12">
                    <textarea name="pos_request_xml" id="round2" disabled={this.state.editXml ? false : true} style={{ width: '100%', height: '300px' }} onChange={this.handleInputChange}>
                      {this.state.pos_request_xml}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                // onClick={() => {
                //   this.handleTimer()
                // }}
                >
                  Close
                </button>
              </div>
              <div style={{ display: "none" }}>
                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
class ManualSyncButton extends Component<{ row: any }, { [x: number]: any, pos_request_xml: any, order_id: any, loader: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      // pos_request_xml: this.props.row.pos_xml,
      pos_request_xml:"",
      order_id: "",
      loader: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }
 
  componentDidMount() {
    const { row } = this.props
    this.setState({ order_id: row.order_id })
  }
  
  handleSaveSync = () => {
    const { row } = this.props
    const { order_id } = this.state;
    const data = {
      order_id: order_id ? order_id : "",
      // pos_xml: this.state.pos_request_xml
      // pos_xml: this.props.data.orderXML.pos_xml
    }
    console.log(this.props.data.orderXML,"Respomnse");
    
    this.props.data.newmanualSyncOrder(row.order_id, data, null, this.updateLoaderState);
  }
  // handleCLick=()=>{
  //   const { row } = this.props
  //   this.props.data.getOrderXML(row.order_id)

  //   setTimeout(()=>{
  //     this.handleSaveSync()
  //   },500)

  // }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  updateLoaderState = (loaderValue: any) => {
    this.setState({ loader: loaderValue });
  }
  render() {
    const { row } = this.props;
    return (
      <div>
        {/* this.props.data.clickmenuSync */}
        {(this.state.loader) ?
          <button
            type="button"
            title="Re-Push"
            className="btn btn-sm btn-outline-primary"
            disabled={this.props.data.clickmenuSync}
          >
            <Loader type="TailSpin" color="red" height={30} width={30} />
          </button> :
          <button
            type="button"
            title="Re-Push"
            className="btn btn-sm btn-outline-primary"
            disabled={(row.check_number === '') ? false : true}
            onClick={this.handleSaveSync}
          >
            <i className="fa fa-undo"></i>
          </button>
        }
      </div >
    );
  }
}
class StoresEditor extends React.Component<{ data: any, row: any, closeTimer: any }, { store_id: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      store_id: ""
    }
  }
  componentDidMount() {
    const { row } = this.props
    this.setState({ store_id: row.store_id })

  }
  handleSave = () => {
    const { row } = this.props
    selectedStoreIdForOrderTransfer = this.state.store_id
    this.openModal.click();
    this.props.closeTimer()
    this.props.data.updateSelectedRow(row)
  }
  onSearch = (e: any) => {
    this.setState({ store_id: e.value, })
  }

  render() {
    let activeStore: any = []
    const { data, row } = this.props;
    const { store_id } = this.state;
    let tokenData: any = jwt.decode(localStorage.token)
    let roleId = tokenData.role_id;
    data.stores.map((obj: any) => {
      if (obj.is_active == 1) {
        activeStore.push(obj)
      }
    })
    return (
      <div>
        <div>
          {row.is_order_transfer == 0
            ?
            <Select
              isSearchable={true}

              onChange={this.onSearch}

              placeholder={row.store_name}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize: "12px" }) }}
              options={activeStore}
              isOptionDisabled={(option: any) => option.store_id === row.store_id && true}
              isLoading={data.stores.length == 0 ? true : false}
            />
            : <p title={row.store_name}>{row.store_name.length < 20 ? row.store_name : `${row.store_name.slice(0, 20)}...`}</p>
          }
          {(row.is_order_transfer == 0 && store_id !== row.store_id) && <> <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span></>}
        </div>
        <div style={{ display: "none" }}>
          <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewordertransfer`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
        </div>
      </div>
    );
  }
}
const orderTransferEditor = (cell: any, row: any, props: any, closeTimer: any) => {
  return (
    <StoresEditor row={row} data={props} closeTimer={closeTimer} />
  )
}


function priceFormatter(cell: any, row: any) {
  if (row.aggregator_orderId) {
    return `${priceunit}${cell}`;
  } else {
    return `${priceunit}${Math.round(cell)}`;
  }
}

function isOrderTransferFormatter(cell: any, row: any) {
  if (row.is_order_transfer == 0) {
    return <p title="No">No</p>;
  } else {
    return <p title="Yes">Yes</p>;
  }
}
function complaintFormatter(cell: any, row: any, props: any) {
  return (
    <button
      title="Feedback"
      className="btn btn-outline-primary"
      data-toggle="modal"
      data-target={`#RegFeedback`}
      onClick={() => {
        props.updateSelectedRow(row)
      }}
    >
      <i className="fa fa-comment"></i>
    </button>
  );
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
const xmlformatter = (cell: any, row: any, props: any) => {
  return (
    <XMLEditor row={row} data={props} />
  )
}
const pulseReqformatter = (cell: any, row: any, props: any) => {
  return (
    <ReqXMLEditor row={row} data={props} />
  )
}
const manualSyncformatter = (cell: any, row: any, props: any) => {
  return (
    <ManualSyncButton  row={row} data={props} />
  )
}
function skeltonFormatter() {
  return (
    <Skelton></Skelton>
  );
}
function InvoiceComp(props: any) {
  return (
    <div id={`viewinvoice`} data-backdrop='static' data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
      <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
            <button type="button"
              data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
          </div>
          <div className="modal-body">
            <Invoice data={props.data} orderId={selectedOrderId} />
          </div>
        </div>
      </div>
    </div>
  )
}
function ComplaintComp(props: any) {
  return (
    <div
      id={`RegFeedback`}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      className="modal fade text-left"
    >
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">
              Customer Feedback
            </h4>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Complaint />
          </div>
        </div>
      </div>
    </div>
  );
}
class OrderTransferComp extends React.Component<{ data: any, selectedRow: any, updateLoader: any, startTimer: any }, { [x: number]: any, order_transfer_caller: any, transferReason: any, loader: any }> {
  openModal: any;
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      order_transfer_caller: "",
      transferReason: "",
      loader: false
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.orderTransfer = this.orderTransfer.bind(this);
  }
  updateLoaderState = (loaderValue: any) => {
    this.setState({ loader: loaderValue });
  }
  isOrderTransferReady = () => {
    const { order_transfer_caller, transferReason } = this.state;
    return (order_transfer_caller !== "" && transferReason !== "");
  }
  orderTransfer(order_id: any) {
    let { selectedRow } = this.props;
    const data: any = {
      store_id: selectedStoreIdForOrderTransfer,
      order_transfer_caller: this.state.order_transfer_caller,
      order_transfer_reason: this.state.transferReason,
      is_order_transfer: 1
    }
    this.setState({ loader: true })
    this.props.data.orderTransfer(order_id, data, this.closeModal, this.updateLoaderState, this.props.updateLoader, this.props.startTimer);
  }
  handleInputChange(event: { target: { name: any; value: any; }; }) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const { data, selectedRow } = this.props;
    return (
      <div>
        <div id={`viewordertransfer`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
          <div role="document" className="modal-dialog" style={{ minWidth: "600px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">Order Transfer</h4>
                <button type="button" data-dismiss="modal" aria-label="Close" className="close" onClick={() => this.props.startTimer()}><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <h6>Are you sure,you want to transfer this order from one store to another?</h6>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <select
                        name="transferReason"
                        onChange={this.handleInputChange}
                        className="form-control mt-2">
                        <option value="">Select Reason</option>
                        {data.transferReasons &&
                          data.transferReasons.map((reason: any, index: any) => (
                            <option key={index} value={reason.id}>{reason.reason}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-group">
                      <input
                        id="order_transfer_caller"
                        style={{ fontSize: 15 }}
                        type="text"
                        name="order_transfer_caller"
                        placeholder="Caller Name"
                        className="input-material"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                </div>
                <div className="form-group d-flex justify-content-end mt-4">
                  {this.state.loader ?
                    <button className='btn btn-primary btn-danger'>
                      <Loader type="TailSpin" color="red" height={30} width={30} />
                    </button> :
                    <button className='btn btn-primary btn-danger' disabled={!this.isOrderTransferReady()} onClick={() => this.orderTransfer(selectedRow.order_id)}>Save</button>
                  }
                </div>
              </div>
              <div style={{ display: "none" }}>
                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
//here
class order extends Component<OrderProps, OrderState> {
  closeModal: any;
  constructor(props: any) {
    super(props);
    this.state = {
      days: 0,
      orderId: '',
      startDate: "",
      endDate: "",
      validTimeFlag: "",
      store_id: "",
      ordersArray: null,
      stores: [],
      loader: true,
      startTimer: 0,
      refresh: true,
      debounceTimeout: null,
      searchQuery: ""
    }
    this.handleDaysFilter = this.handleDaysFilter.bind(this);
    this.handleValidTime = this.handleValidTime.bind(this);
    this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
  }

  componentDidMount() {
    let days = this.state;
    //this.props.ordersList(days,null,this.updateLoader);
    this.props.storesList();
    this.props.complaintReasonsList();
    this.props.orderStatusList();
    this.props.cancelOrdersReasons();
    this.props.orderTransferReasons();
    let tokenData: any = jwt.decode(localStorage.token)
    let roleId = tokenData.role_id;
    if(roleId==8){
      this.props.getAgentStats(days);
    }
  }
  rowClassNameFormat(row: any, rowIdx: any) {
    // row is whole row object
    // rowIdx is index of row
    if (row.aggregator_orderId) {
      return 'td-column-function-aggregator-example';
  } else {
      if (row.future_status == 1 && row.order_status_code == 1) {
          return 'td-column-function-odd-example tr-pending-orders'
      } else if (row.future_status == 1) {
          return 'td-column-function-odd-example'
      } else if (row.isNew == true && row.order_status_code == 1 && row.is_order_transfer == 1) {
          return 'td-column-function-even-example tr-transfered-orders'
      } else if (row.isNew == true && row.order_status_code == 1) {
          return 'td-column-function-even-example tr-pending-orders'
      } else if (row.isNew == true && row.is_order_transfer == 1) {
          return 'td-column-function-even-example tr-transfered-orders'
      } else if (row.isNew == true) {
          return 'td-column-function-even-example'
      } else if (row.order_status_code == 1) {
          return 'tr-pending-orders'
      } else if (row.is_order_transfer == 1) {
          return 'tr-transfered-orders'
      } else {
          return ''
      }
  }
  }
  handleValidTime(event: { target: { name: any; value: any; }; }) {
    let futureDatetime = new Date(event.target.value)
    let validMinDateTime = new Date();
    if (futureDatetime <= validMinDateTime) {
      this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
    } else {
      this.setState({ validTimeFlag: false })
      toast.error("Invalid date and time selected");
    }
  }
  isOrderDateReady = () => {
    let { startDate, endDate, validTimeFlag } = this.state;
    return (startDate !== "" && endDate !== "" && validTimeFlag == true);
  }
  handleDaysFilter(event: { target: { name: any; value: any; }; }) {
    let obj = { days: event.target.value };
    this.props.getAgentStats(obj);
    this.props.ordersList(obj, null, this.updateLoader);

    // console.log("event.target.value",event.target.value);
  }
  // days orders here
  handleSubmit() {
    let { startDate, endDate } = this.state;
    let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
    let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
    let data: any = {
      start: startDateUtc,
      end: endDateUtc,
      pageNumber: 1,
      pageSize: DEFUALT_PAGE_SIZE
    }
    var start = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
    var end = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
    //Difference in number of days
    let days = moment.duration(end.diff(start)).asDays();

    let days_round = Math.round(days);
    if (startDate >= endDate) {
      toast.error("End date cannot be less than start date")
    } else if (days_round > 1) {
      toast.error("Maximum 2 day can be selected")
    } else {
      this.setState({ startTimer: this.state.startTimer + 1 ,listType:"dateTime"});
      this.props.ordersList(data, true, this.updateLoader);
    }
  }
  handleInput = (event: { target: { name: any; value: any; }; }) => {
    this.setState({ orderId: event.target.value })
  };

  handleSearchOrder = (event: any) => {
    event.preventDefault();
    let { orderId } = this.state;
    this.props.searchOrder(orderId)
  }

  refreshOrders = () => {
    let obj = {
      days: 0
    }
    this.props.ordersList(obj, null, this.updateLoader);
  }

  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.value !== 'all') {
      let orders: any = this.props.orders && this.props.orders.filter(order => e.value == order.store_id);
      this.setState({ ordersArray: orders })
      console.log("e.value.store_id", orders)
      // ==store.store_id

    } else {
      this.setState({ ordersArray: this.props.orders })
    }
    //console.log("E ", e.value);
  };
  //here
  updateLoader = (value: any) => {
    this.setState({ loader: value })
  }

  handlePagination = () => {
    let { startDate, endDate, days } = this.state;

    let obj :any= {
      pageNumber: parseInt(this.props.pageNumber),
      pageSize: parseInt(this.props.pageSize)
    }
    if (startDate && endDate) {
      let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
      let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
      obj.start = startDateUtc
      obj.end = endDateUtc
    }
    else {
      obj.days = days;
    }
    this.props.ordersList(obj, null, this.updateLoader)
  }

  clearDateTime = () => {
    this.setState({
      startDate: "",
      endDate: "",
    })
  }
  handleTimer = () => {
    if (this.state.refresh) {
      this.setState({ refresh: false })
    } else {
      this.setState({ refresh: true })
    }
    this.closeModal.click();
  }
  closeTimer = () => {
    this.setState({ refresh: false })
  }
  startTimer = () => {
    this.setState({ refresh: true })
  }
  // handleSearchQuery = (e: any) => {
  //   this.setState({ searchQuery: e.target.value })
  //   if (e.target.value.length >= 3) {
  //     setTimeout(() => {
  //       this.props.searchOrdersByQuery(e.target.value)
  //     }, 400)
  //   }
  //   if (!e.target.value) {
  //     let obj = {
  //       days: this.state.days,
  //       pageNumber: 1,
  //       pageSize: DEFUALT_PAGE_SIZE
  //     }
  //     this.props.ordersList(obj, null, this.updateLoader);
  //     this.setState({ startTimer: this.state.startTimer + 1 });
  //   }
  // }
  handleSearchQuery = (e: any) => {
    const { startDate, endDate } = this.state;
    const startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm');
    const endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm');
    let data: any = {
      start: startDateUtc,
      end: endDateUtc
    };

    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    clearTimeout(this.state.debounceTimeout);

    const newDebounceTimeout = setTimeout(() => {
      if (searchQuery && searchQuery.length >= 3) {
        console.log(this.state.days, "asfdsaffsaafa");

        if (this.state.listType === "dateTime") {
          this.props.searchOrdersByQuery(searchQuery, data);
        } else {
          const obj = {
            days: 0
          };
          this.props.searchOrdersByQuery(searchQuery, obj);
        }
      } else if (!searchQuery) {
      
        let { startDate, endDate, days } = this.state;

        let obj:any = {
          pageNumber: parseInt(this.props.pageNumber),
          pageSize: parseInt(this.props.pageSize)
        }
        if (startDate && endDate) {
          let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
          let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
          obj.start = startDateUtc
          obj.end = endDateUtc
        }
        else {
          obj.days = days;
        }
        this.props.ordersList(obj, null, this.updateLoader)
      }
    }, 400);
    this.setState({ debounceTimeout: newDebounceTimeout });
  };

  render() {
    const dataa = [
      { id: 1, name: 'John Smith', age: 32, email: 'john.smith@example.com' },
      { id: 2, name: 'Jane Doe', age: 27, email: 'jane.doe@example.com' },
      { id: 3, name: 'Bob Johnson', age: 41, email: 'bob.johnson@example.com' },
      { id: 4, name: 'Alice Williams', age: 25, email: 'alice.williams@example.com' },
      { id: 5, name: 'Jack Brown', age: 38, email: 'jack.brown@example.com' },
      { id: 6, name: 'Linda Davis', age: 29, email: 'linda.davis@example.com' },
      { id: 7, name: 'Mike Garcia', age: 45, email: 'mike.garcia@example.com' },
      { id: 8, name: 'Samantha Lee', age: 31, email: 'samantha.lee@example.com' },
      { id: 9, name: 'David Nguyen', age: 36, email: 'david.nguyen@example.com' },
      { id: 10, name: 'Emily Taylor', age: 24, email: 'emily.taylor@example.com' }
    ];
    let { orders, agentStats } = this.props;
    let tokenData: any = jwt.decode(localStorage.token)
    let roleId = tokenData.role_id;
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Orders Not Found"
    };
    let storesArray = this.props.stores;
    let newObj = {
      value: 'all',
      label: "All"
    }
    if (storesArray && storesArray.length > 0) {
      if (storesArray[0].value !== 'all') {
        storesArray.unshift(newObj)
      }
    }
    let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')

    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  {roleId === 8 ?
                    <div className="d-flex">
                      <h4 className="mt-2 mr-4">Agent Dashboard <button title={this.state.refresh == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.state.refresh == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button></h4>
                      {this.state.refresh && <ReactTimer timerKey={this.state.startTimer} days={this.state.days} updateLoader={this.updateLoader} clearDateTime={this.clearDateTime} />}
                    </div>
                    :
                    <div className="d-flex">
                      <h4 className="mt-2 mr-4">Orders <button title={this.state.refresh == false ? "Enable Refresh" : "Disable Refresh"} data-toggle="modal" data-target={`#enableTimer`} className={this.state.refresh == false ? "btn btn-outline-success ml-2" : "btn btn-outline-danger ml-2"}><i className={"fa fa-refresh"}></i></button> </h4>
                      {this.state.refresh && <ReactTimer timerKey={this.state.startTimer} days={this.state.days} updateLoader={this.updateLoader} clearDateTime={this.clearDateTime} />}
                    </div>
                  }

                  {/* <button disabled={true} className="btn btn-danger mr-3" style={{ padding: '0 15px', height: "40px" }} onClick={this.refreshOrders} ><i className="fa fa-refresh"></i></button> */}

                  <div className="d-flex " style={{ justifyContent: "space-between" }}>
                    {roleId === 8 &&
                      <div className="stats-place">
                        {agentStats && agentStats.dailyTarget &&
                          <strong>Daily Target: {priceunit + agentStats.dailyTarget} </strong>
                        }
                        {agentStats.dailyAchievedTarget &&
                          <strong>Daily Achieved Target: {priceunit + agentStats.dailyAchievedTarget}</strong>
                        }
                      </div>
                    }
                    {/* <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                      <option value="0">Today</option>
                      <option value="1">Yesterday</option>
                      <option value="7">7 Days</option>
                      <option value="15">15 Days</option>
                      <option value="30">30 Days</option>
                      <option value="60">60 Days</option>
                      <option value="90">90 Days</option>
                    </select> */}
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                          <div className="form-group">
                            <input
                              id="start"
                              type="datetime-local"
                              name="startDate"
                              max={maxDate}
                              value={this.state.startDate}
                              className="input-material"
                              onChange={this.handleValidTime}
                            />
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-12">
                          <div className="form-group mb-1">
                            <input
                              id="end"
                              type="datetime-local"
                              name="endDate"
                              max={maxDate}
                              value={this.state.endDate}
                              className="input-material"
                              onChange={this.handleValidTime}
                            />
                          </div>
                          <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </header>
            {roleId === 8 &&
              <>
                <section className="dashboard-counts no-padding mt-4">
                  <div className="container-fluid">
                    <div className="row bg-white p-2">
                      <div className="col">
                        <h6> Calls History </h6>
                      </div>
                    </div>
                    <div className="row no-padding bg-white has-shadow">
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-orange "><i className="fa fa-phone"></i></div>
                          <div className="title"><span>Total<br />Calls </span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.totalCalls / 100}%`, height: "4px" }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-red"></div>
                            </div>
                          </div>
                          <div className="number"><strong>{agentStats.totalCalls}</strong></div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-green"><i className="fa fa-check"></i></div>
                          <div className="title"><span>Orders<br />Converted</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.completeCalls / 100}%`, height: "4px" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-orange"></div>
                            </div>
                          </div>
                          <div><strong>{agentStats.completeCalls}</strong></div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon  bg-blue "><i className="fa fa-clock-o"></i></div>
                          <div className="title"><span>Average<br />Order Time</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.avgCallTime / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                            </div>
                          </div>
                          <div className="number"><strong>{agentStats.avgCallTime} Mins</strong></div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-red "><i className="fa fa-clock-o"></i></div>
                          <div className="title"><span>Total<br />Order Time</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.ignoreCalls / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                            </div>
                          </div>
                          <div className="number"><strong>{agentStats.totalCallTime}</strong></div>
                        </div>
                      </div>
                    </div>

                  </div>
                </section>
                <section className="dashboard-counts no-padding mt-4">
                  <div className="container-fluid">
                    <div className="row bg-white p-2">
                      <div className="col">
                        <h6>Agent Sales Achievement </h6>
                      </div>
                    </div>
                    <div className="row no-padding bg-white has-shadow">
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-orange "><i className="fa fa-money"></i></div>
                          <div className="title"><span>Total<br />Sales</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.totalSales / 100}%`, height: "4px" }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-red"></div>
                            </div>
                          </div>
                          <div className="number"><strong>{agentStats.totalSales}</strong></div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-green"><i className="fa fa-check"></i></div>
                          <div className="title"><span>Ticket<br />Average</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.avgTicket / 100}%`, height: "4px" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-orange"></div>
                            </div>
                          </div>
                          <div><strong>{agentStats.avgTicket}</strong></div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon  bg-blue "><i className="fa fa-envelope"></i></div>
                          <div className="title"><span>Feedback<br />Submitted</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.avgCallTime / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                            </div>
                          </div>
                          <div className="number"><strong>{agentStats.infoCalls}</strong></div>
                        </div>

                      </div>
                      <div className="col-xl-3 col-sm-6">

                        <div className="item d-flex align-items-center">
                          <div className="icon bg-red "><i className="fa fa-ban"></i></div>
                          <div className="title"><span>Cancelled<br />Orders</span>
                            <div className="progress">
                              <div role="progressbar" style={{ width: `${agentStats.ignoreCalls / 100}%`, height: "4px" }} aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} className="progress-bar bg-green"></div>
                            </div>
                          </div>
                          <div className="number"><strong>{agentStats.ignoreCalls}</strong></div>
                        </div>

                      </div>
                    </div>

                  </div>
                </section>
              </>
            }
            {/* <section className="tables mt-4 no-padding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between py-3">
                          <h4 className="mt-2">Statistics</h4>
                        </div>
                        {agentStats &&
                          <div className="d-flex">

                            <table
                              style={{ width: "50%" }}
                              className="table-bordered"
                            >
                              <tr>
                                <td>Total calls</td>
                                <td>{agentStats.totalCalls}</td>
                                <td>Transferred</td>
                                <td>0</td>
                                <td>Last Phone No</td>
                                <td>Unknown</td>
                              </tr>
                              <tr>
                                <td>Answered</td>
                                <td>{agentStats.completeCalls}</td>
                                <td>Average Call time</td>
                                <td>{agentStats.avgCallTime}</td>
                                <td>Target Period</td>
                                <td>25</td>
                              </tr>
                              <tr>
                                <td>Ignored</td>
                                <td>{agentStats.ignoreCalls}</td>
                                <td>Max Period</td>
                                <td>13.7</td>
                                <td>Idle Time</td>
                                <td>23</td>
                              </tr>
                            </table>
                            <table
                              style={{ width: "50%" }}
                              className="table-bordered"
                            >
                              <tr>
                                <td>Total calls</td>
                                <td>19</td>
                                <td>No. of delivery</td>
                                <td>19</td>
                              </tr>

                              <tr>
                                <td>Average Check</td>
                                <td>775</td>
                                <td>No. TDA</td>
                                <td>17</td>
                              </tr>
                              <tr>
                                <td>Total Sales</td>
                                <td>{agentStats.totalSales}</td>
                                <td></td>
                              </tr>
                            </table>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}


            <section className="tables pt-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-5 col-md-4 col-12 zclass">
                            <Select
                              isSearchable={true}
                              defaultValue={{ label: 'All', value: 'all' }}
                              // onChange={(e) => this.onSearch(e)}
                              options={storesArray}
                              isLoading={storesArray.length == 0 ? true : false}
                              onChange={(e, i) => this.handleStoresInputChange(e, i)}
                            />
                          </div>
                          <div className="col-lg-5 col-md-4 col-12">
                            <div className="form-group">
                              <input id="orderId" type="number" value={this.state.orderId} name="orderId" required placeholder="Search by order id" className="input-material" onChange={this.handleInput} />
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-12 float-right mt-1">
                            <div className="form-group">
                              <button onClick={this.handleSearchOrder} disabled={this.state.orderId == ""} className="btn btn-danger btn-block">Search Order</button>
                            </div>
                          </div>
                        </div>
                        <input type='text' value={this.state.searchQuery} onChange={this.handleSearchQuery} placeholder='Search' className="input-crm-search" />
                        <div className="topPagination">
                          <PaginationComp handlePagination={this.handlePagination} />
                        </div>
                        {this.state.loader ?
                          <div style={{ marginTop: "50px" }}>
                            <BootstrapTable
                              version="4"
                              data={dataa}
                              //search={true}
                              options={options}
                            >

                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField='1'
                                dataFormat={skeltonFormatter}
                                csvHeader="Order Id"
                                dataSort={true}
                                isKey
                              >
                                Order Id
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                              >
                                Details
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} >Store</TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Order Type"
                                columnTitle
                              >
                                Order Type
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Order Channel"
                                columnTitle
                              >
                                Order Channel
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Status' columnTitle>Status</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Agent Name"
                                columnTitle
                              >
                                Agent Name
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Customer Name"
                                columnTitle
                              >
                                Name
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Phone"
                                columnTitle
                              >
                                Phone
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Address"
                                columnTitle
                              >
                                Address
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Price"
                              >
                                Order Price
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Discount"
                              >
                                Discount
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Payment Method"
                                columnTitle
                              >
                                Payment Method
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Cancel Reason"
                                columnTitle
                              >
                                Cancel Reason
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Caller For Order Transfer' columnTitle>Caller For Order Transfer</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='Order Transfer' columnTitle>Order Transfer</TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Order Transfer Reason"
                                columnTitle
                              >
                                Order Transfer Reason
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                                csvHeader="Order Recieved"
                                columnTitle
                              >
                                Order Recieved
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={skeltonFormatter} csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataFormat={skeltonFormatter} >Re Push</TableHeaderColumn>
                              {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={pulseReqformatter} formatExtraData={this.props} export={false}>Pulse Request</TableHeaderColumn>
                            <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={xmlformatter} formatExtraData={this.props} export={false}>Pulse Response</TableHeaderColumn> */}
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataFormat={skeltonFormatter}
                              >
                                Action
                              </TableHeaderColumn>


                            </BootstrapTable>
                          </div>
                          : <div style={{ marginTop: "50px" }}>
                            <BootstrapTable
                              version="4"
                              data={this.state.ordersArray == null ? orders : this.state.ordersArray.length === 0 ? [] : this.state.ordersArray}
                              //search={true}
                              // pagination={orders.length > 10 && true}
                              options={options}
                              trClassName={this.rowClassNameFormat}
                              hover
                            >

                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="order_id"
                                csvHeader="Order Id"
                                dataSort={true}
                                isKey
                              >
                                Order Id
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="action"
                                dataFormat={actionFormatter}
                                formatExtraData={this.props}
                                export={false}
                              >
                                Details
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='store_id' dataFormat={(cell, row, props) => orderTransferEditor(cell, row, props, this.closeTimer)} formatExtraData={this.props} >Store</TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="delivery_status"
                                csvHeader="Order Type"
                                columnTitle
                              >
                                Order Type
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="order_channel"
                                csvHeader="Order Channel"
                                columnTitle
                              >
                                Order Channel
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_time_json' dataFormat={calculateDeliveryTime} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_status_description' csvHeader='Status' columnTitle>Status</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='check_number' csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='failure_reason' csvHeader='Pulse Status' columnTitle>Pulse Status</TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="agent_name"
                                csvHeader="Agent Name"
                                columnTitle
                              >
                                Agent Name
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="login_name"
                                csvHeader="Customer Name"
                                columnTitle
                              >
                                Name
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="phone_number"
                                csvHeader="Phone"
                                columnTitle
                              >
                                Phone
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="delivery_address"
                                csvHeader="Address"
                                columnTitle
                              >
                                Address
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="order_grossprice"
                                dataFormat={priceFormatter}
                                csvHeader="Price"
                              >
                                Order Price
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="discount"
                                dataFormat={priceFormatter}
                                csvHeader="Discount"
                              >
                                Discount
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="payment_method"
                                csvHeader="Payment Method"
                                columnTitle
                              >
                                Payment Method
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="reason"
                                csvHeader="Cancel Reason"
                                columnTitle
                              >
                                Cancel Reason
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='order_transfer_caller' csvHeader='Caller For Order Transfer' columnTitle>Caller For Order Transfer</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='is_order_transfer' csvHeader='Order Transfer' dataFormat={isOrderTransferFormatter} columnTitle>Order Transfer</TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="order_transfer_reason"
                                csvHeader="Order Transfer Reason"
                                columnTitle
                              >
                                Order Transfer Reason
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField="date_created"
                                csvHeader="Order Recieved"
                                dataFormat={dateFormatter}
                                columnTitle
                              >
                                Order Recieved
                              </TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                              <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={manualSyncformatter}  formatExtraData={this.props} export={false}>Re Push</TableHeaderColumn>
                              {/* <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={pulseReqformatter} formatExtraData={this.props} export={false}>Pulse Request</TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10 }} dataField='action' dataFormat={xmlformatter} formatExtraData={this.props} export={false}>Pulse Response</TableHeaderColumn> */}
                              <TableHeaderColumn
                                thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }}
                                dataField='action'
                                dataFormat={complaintFormatter}
                                formatExtraData={this.props}
                                export={false}>
                                Action
                              </TableHeaderColumn>


                            </BootstrapTable>
                          </div>
                        }
                        <div className="bottomPagination">
                          <PaginationComp handlePagination={this.handlePagination} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
        <div>
          <InvoiceComp data={this.props} />
          <ComplaintComp data={this.props} />
          <OrderTransferComp data={this.props} selectedRow={this.props.selectedRow} updateLoader={this.updateLoader} startTimer={this.startTimer} />
          <div id="enableTimer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">Auto Refresh</h4>
                  <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                </div>
                <div className="modal-body">
                  <p>Are you sure,you want to {this.state.refresh == false ? "enable" : "disable"} auto refresh?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" ref={el => this.closeModal = el} data-dismiss="modal" className="btn btn-secondary">Close</button>
                  <button onClick={this.handleTimer} className="btn btn-primary">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function calculateDeliveryTime(cell: any, row: any) {
  let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
  let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.order_placed + DeliveryTime.prep + DeliveryTime.bake + DeliveryTime.quality_check : 0
  return (
    <div>
      <span {...((row.order_status_code == 6 && row.delivery_time_json) && { title: `${ActualDeliveryTime} min` })}> {(row.order_status_code == 6 && row.delivery_time_json) && `${ActualDeliveryTime} min`} </span>
    </div>
  )
}
function dateFormatter(cell: any) {
  var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
  DateCreated = DateCreated[1] + " , " + DateCreated[0];
  return (
    <div>
      <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    orders: state.order.data,
    stores: state.customer.storesList,
    agentStats: state.order.agent_stats,
    Items: state.order.orderItems,
    orderDetail: state.order.order,
    reasonsList: state.customerComplaint.complaintReasonList,
    statusList: state.order.orderStatus,
    cancelReasons: state.order.cancelReasons,
    transferReasons: state.order.orderTransferReasons,
    clickmenuSync: state.order.clickmenuSync,
    pageSize: state.order.pageSize,
    pageNumber: state.order.pageNumber,
    selectedRow: state.order.selectedRow,
    orderXML: state.order.orderXML,

  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    ordersList: (obj: any, datetime: any, updateLoader?: any) => {
      dispatch(ordersList(obj, datetime, updateLoader));
    },
    newmanualSyncOrder: function (id: any, data: any, modal: any, updateLoaderState?: any) {
      dispatch(newmanualSyncOrder(id, data, modal, updateLoaderState))
    },
    getTaxValue: (state_id: any) => {
      dispatch(getTaxValue(state_id))
    },
    getAgentStats: (days: any) => {
      dispatch(getAgentStats(days))
    },
    // customerDetail: (data: any) => {
    //     dispatch(customerDetail(data))
    // },
    // handleCustomerInput: (event: any) => { dispatch(handleCustomerInput(event)) },
    // addCustomer: (data: any) => { dispatch(addCustomer(data)); },
    // validateEmail: (event: any) => { dispatch(validateEmail(event)) },
    // handleAddressInput: (event: any) => { dispatch(handleAddressInput(event)) },
    storesList: function () {
      dispatch(getStores());
    },
    getOrderXML: function (id: any) {
      dispatch(getOrderXML(id))
  },
    // getTaxValue: (state_id: any) => {
    //     dispatch(getTaxValue(state_id))
    // },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    showorderItems: function (id: any, orders: any) {
      dispatch(showorderItems(id, orders))
    },
    complaintReasonsList: function () {
      dispatch(complaintReasonsList());
    },
    orderStatusList: function () {
      dispatch(orderStatusList())
    },
    cancelOrdersReasons: () => {
      dispatch(cancelOrdersReasons())
    },
    orderTransferReasons: () => {
      dispatch(orderTransferReasons())
    },
    updateOrder: function (id: any, data: any, history: any) {
      dispatch(updateOrder(id, data, history))
    },
    orderTransfer: function (id: any, data: any, closeModal: any, updateLoaderState: any, updateLoader?: any, startTimer?: any) {
      dispatch(orderTransfer(id, data, closeModal, updateLoaderState, updateLoader, startTimer))
    },
    searchOrder: (orderId: any) => {
      dispatch(searchOrder(orderId))
    },
    updateSelectedRow: (row: any) => {
      dispatch(updateSelectedRow(row))
    },
    searchOrdersByQuery: (query: any,data:any) => {
      dispatch(searchOrdersByQuery(query,data))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(order);
