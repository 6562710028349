import { useEffect } from "react";
import { Pagination } from "antd";
import { connect, useDispatch } from "react-redux";
import { DEFUALT_PAGE_SIZE } from "../client-config";

function PaginationComp(props:any) {
  const dispatch=useDispatch();

  const handlePagination = (pageNum:any,pSize:any) => {
    dispatch({ type:"SET_PAGINATION", payload:{ pageNum, pSize } })

    setTimeout(()=>{   
      props.handlePagination() // call after redux var set
    },100)

  };

  useEffect(()=>{
    props.handlePagination()
    return ()=>{
      dispatch({ type:"SET_PAGINATION", payload:{ pageNum: 1, pSize: DEFUALT_PAGE_SIZE } })
    }
  },[])

  return <>
        {
        props.total_orders_count > DEFUALT_PAGE_SIZE ? 
          <Pagination
            onChange={(pageNumber:any, pageSize:any) => {
              handlePagination(pageNumber,pageSize);
            }}
            current={props.pageNumber}
            total={props.total_orders_count}
            pageSize={props.pageSize}
            // showSizeChanger={true}
            // showQuickJumper={true}
            jumpPrevIcon={
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            }
            jumpNextIcon={
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            }
            showLessItems
            showTotal={(total:any) => `Total ${total} orders`}
          />
        : <></>
        }
      
        </>
}

const mapStateToProps = (state: any) => {
  return {
    pageSize: state.order.pageSize,
    pageNumber: state.order.pageNumber,
    total_orders_count: state.order.total_orders_count,
  };
};

export default connect(mapStateToProps, null)(PaginationComp);
